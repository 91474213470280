import { selectorFamily } from 'recoil';
import { LearningUnitEntity } from '../generated-api';
import { apiClientSelector } from './api';

export const unitsByCourseIdSelector = selectorFamily<LearningUnitEntity[], string>({
  key: 'unitsByCourseIdSelector',
  get:
    (course_id) =>
      async ({ get }) => {
        const client = get(apiClientSelector);
        const result = await client.learningUnitControllerGetLearningUnitsByCourseId(course_id);
        return result.data
      },
});

export const unitSelector = selectorFamily<LearningUnitEntity, string>({
  key: 'unitSelector',
  get:
    (unitId) =>
      async ({ get }) => {
        const client = get(apiClientSelector);
        const result = await client.learningUnitControllerGetLearningUnitById(unitId);
        return result.data;
      },
});
