import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { apiClientSelector } from "../../../selectors/api";


const { Dragger } = Upload;

const Uploader = styled(Dragger)`

`;


type Props = {
  contentId: string;
  onUpload: () => void;
}

const MovieUploader = (props: Props) => {
  const { onUpload, contentId } = props;
  const [uploading, setUploading] = useState<boolean>(false);
  const api = useRecoilValue(apiClientSelector);

  return (
    <Uploader
      multiple={false}
      method="PUT"
      disabled={uploading}
      headers={{
        'Content-Type': 'application/octet-stream'
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customRequest={async (options: any) => {
        if (uploading) {
          return;
        }
        setUploading(true);
        try {
          const uploadUrl = (await api.movieControllerIssueMovieUploadURL(
            {
              fileName: options.file.name,
              contentId,
            },
          )).data;

          await fetch(uploadUrl, {
            method: 'PUT',
            body: options.file,
          });

          onUpload();
        } catch (e) {
          console.log(e);
        } finally {
          setUploading(false);
        }

      }}

    >

      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        {uploading && "動画アップロード中です..."}
        {!uploading && "こちらをクリック、または動画ファイルをドラッグ&ドロップしてください"}
      </p>

    </Uploader >
  );
};

export default MovieUploader;