import { selectorFamily } from 'recoil';
import { apiClientSelector } from './api';

export const userNameSelector = selectorFamily<string, string>({
  key: 'userDetailSelector',
  get: (userId) => async ({ get }) => {
    const client = get(apiClientSelector);
    const result = await client.userControllerGetUserName(userId);
    return result.data;
  },
});
