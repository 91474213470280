import Delimiter from '@editorjs/delimiter';
import EditorJS, { LogLevels, OutputData } from "@editorjs/editorjs";
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import { Button, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { contentMaxWidth } from "../../../constants/content";
import { ContentEntity } from "../../../generated-api";
import { apiClientSelector } from "../../../selectors/api";
import { byteLength } from "../../../selectors/util";

const EDITTOR_HOLDER_ID = 'editor-js'
const LIMIT_CONTENT_SIZE = 380000;

const editorJsTools = {
  header: Header,
  list: List,
  image: {
    class: ImageTool,
    config: {
      uploader: {},
    },
  },
  delimiter: Delimiter,
}

const editorJsInitData = {
  blocks: [
    {
      type: "header",
      data: {
        text: "ここをクリックして編集を開始してください",
        level: 2
      }
    },
  ],
  version: "2.12.4"
}

export const ContentEditor = styled.div`
  padding-left: 60px;
  padding-right: 60px;

  .ce-block__content {
    max-width: ${contentMaxWidth};
  }
  
  .ce-toolbar__content {
    max-width: ${contentMaxWidth};
  }

  .codex-editor__redactor {
    padding-bottom: 60px !important;
  }

  h1.ce-header {
    font-size: 30px;
  }

  h2.ce-header {
    font-size: 28px;
  }

  .image-tool__caption {
    display: none;
  }

`;

type Props = {
  targetContent: ContentEntity;
}

const TextContentEdit = (props: Props) => {
  const { targetContent } = props;

  const api = useRecoilValue(apiClientSelector);

  const editorJsRef = useRef<EditorJS>();
  const editorDOMRef = useRef<HTMLDivElement>(null); // 開発時のみ（useEffectが２回走るため）

  const [editorData, setEditorData] = useState<OutputData>();

  const initEditor = async (initData: OutputData) => {

    const blocksWithImage = await Promise.all(initData.blocks.map(async (item) => {
      if (item.type !== 'image') {
        return item;
      }

      const value = await api.imageControllerIssueImageDownloadURL(item.data.file.url);

      return {
        ...item,
        data: {
          ...item.data,
          file: {
            url: `${value.data}#imageId=${item.data.file.url}`,
          },
        }
      }
    }));


    editorJsTools.image.config.uploader = {
      uploadByFile(file: File) {
        return api.imageControllerIssueImageUploadURL({
          contentId: targetContent.content_id,
          fileName: file.name,
        }).then((result) => {
          return fetch(result.data.url, {
            method: 'PUT',
            body: file,
          }).then(async () => {
            const result2 = await api.imageControllerIssueImageDownloadURL(result.data.imageId);
            return {
              success: 1,
              file: {
                url: `${result2.data}#imageId=${result.data.imageId}`,
              }
            }
          })
        })
      },
    }

    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: 'Error' as LogLevels,
      data: { ...initData, blocks: blocksWithImage },
      onReady: async () => {
        editorJsRef.current = editor;

        // 開発時のみ（useEffectが２回走るため）
        const selector = editorDOMRef.current?.getElementsByClassName('codex-editor');
        if (selector && selector.length > 1) {
          for (let i = 0; selector.length - 1; i += 1) {
            selector[i].remove();
          }
        }

        setEditorData(await editor.saver.save());
      },
      onChange: async () => {
        // eslint-disable-next-line react/no-this-in-sfc
        const editedContent = await editor.saver.save();
        if (byteLength(JSON.stringify(editedContent)) > LIMIT_CONTENT_SIZE) {
          notification.error({
            message: '1ページの最大容量を超過しました',
            description: 'ページを分割してコンテンツ作成をお願いします。',
          });
        }

        // Put your logic here to save this data to your DB
        setEditorData(editedContent);
      },
      // autofocus: true,
      tools: editorJsTools,
    });
  };

  const unLoadCheck = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
  }

  useEffect(() => {
    window.addEventListener('beforeunload', unLoadCheck, false);

    (async () => {
      if (!editorJsRef.current) {
        initEditor(targetContent.content_json ? JSON.parse(targetContent.content_json) : editorJsInitData as OutputData);
      }
    })();

    return () => {
      window.removeEventListener('beforeunload', unLoadCheck, false);

      if (editorJsRef) {
        editorJsRef.current?.destroy();
        editorJsRef.current = undefined;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentEditor id={EDITTOR_HOLDER_ID} ref={editorDOMRef} />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px'
      }}>
        <Button type="primary" onClick={async () => {
          try {
            if (byteLength(JSON.stringify(editorData)) > LIMIT_CONTENT_SIZE) {
              notification.error({
                message: 'Error!',
                description: '容量超過により保存ができないため、ページ分割してください',
              });
              return;
            }
            const saveBlockData = editorData?.blocks.map((item) => {
              if (item.type !== "image") {
                return item;
              }

              return {
                ...item,
                data: {
                  ...item.data,
                  file: {
                    url: item.data.file.url.split('#imageId=')[1],
                  },
                }
              }
            });

            if (!editorData || !saveBlockData) {
              throw new Error('data is undefined');
            }

            await api.contentControllerUpdateTextContent({
              contentId: targetContent.content_id,
              contentJson: JSON.stringify({ ...editorData, blocks: saveBlockData }),
            });
            notification.success({
              message: 'Success!',
              description: '編集内容を保存しました',
            });
          } catch (e) {
            notification.error({
              message: 'Error!',
              description: '編集内容の保存に失敗しました',
            });
          }
        }}>
          編集結果を保存する
        </Button>
      </div>
    </>
  );
};

export default TextContentEdit;