/* eslint-disable jsx-a11y/media-has-caption */
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { useAsyncRetry } from "react-use";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import "video.js/dist/video-js.css";
import { MovieEntity } from "../../../generated-api";
import { apiClientSelector } from "../../../selectors/api";
import MovieUploader from "./MovieUploader";
import PlayMovie from "./PlayMovie";
import VTTUplaoder from "./VTTUploader";

let checkTimerId: NodeJS.Timer;

const DeleteMovieButton = styled(Button)`
  margin-left: 8px;
`;

const MovieConfigHeader = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;
`

type Props = {
  contentId: string;
}

const MovieEdit = (props: Props) => {
  const { contentId } = props;
  const api = useRecoilValue(apiClientSelector);

  const [uploadedMovie, setUploadedMovie] = useState<MovieEntity | null>(null);
  const [movieURL, setMovieURL] = useState<string | null>(null);
  const [vttURL, setVttURL] = useState<string | null>(null);

  const movieByContent = useAsyncRetry(async () => {
    const { data } = await api.movieControllerGetMovieByContentId(
      contentId,
    );

    if (!data) {
      return;
    }

    setUploadedMovie(data);
    // VTTは存在しなくてもURLを取得し、参照する
    const url = (await api.movieControllerGetVTTFileURL(
      data.movie_id,
    )).data;
    setVttURL(url);
  });

  const checkMovieExistence = async (): Promise<void> => {
    if (!uploadedMovie) {
      return;
    }
    // バケットの確認 (バケットの確認後、問題無ければ、URLを返却。NotFoundの場合はError)
    const url = (await api.movieControllerCheckMovieExistence(uploadedMovie?.movie_id)).data;

    // URLの確認
    const check = await fetch(url, {
      method: 'GET',
    });

    if (!check.ok) {
      throw new Error();
    }

    setMovieURL(url);
    clearInterval(checkTimerId);
  }

  // 動画の変換処理が終わっているか5秒ごとにチェックする
  useEffect(() => {
    if (!uploadedMovie) {
      return;
    }

    (async () => {
      try {
        await checkMovieExistence();
      } catch (e) {
        setTimeout(() => {
          checkTimerId = setInterval(async () => {
            await checkMovieExistence();
          }, 5000);
        }, 5000);
      }
    })();

    return () => {
      return clearInterval(checkTimerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedMovie]);

  if (movieByContent.loading) {
    return null;
  }

  return (
    <Card bodyStyle={{ padding: '14px', minHeight: '6rem' }} bordered style={{ marginBottom: '16px' }}>
      <Row gutter={16}>
        <Col span={24}>
          {!uploadedMovie ? (
            <MovieUploader contentId={contentId} onUpload={() => {
              movieByContent.retry();
            }} />
          ) : (
            !movieURL && (
              <>
                <div>エンコード中です<LoadingOutlined /></div>
                <div>長時間経過してもこの表示が変わらない場合は、動画を削除していただき、再度アップロードを行ってください。</div>
              </>
            )
          )}
          {uploadedMovie && (
            <MovieConfigHeader>
              <VTTUplaoder movieId={uploadedMovie.movie_id} />
              <Popconfirm
                title="動画を削除してよろしいですか？"
                onConfirm={async () => {
                  await api.movieControllerDeleteMovie(uploadedMovie.movie_id);
                  message.success('動画が削除されました');
                  clearInterval(checkTimerId);
                  setUploadedMovie(null);
                  setMovieURL(null);
                }}
                okText="はい"
                cancelText="いいえ"
              >
                <DeleteMovieButton
                  danger
                >
                  動画を削除する
                </DeleteMovieButton>
              </Popconfirm>
            </MovieConfigHeader>
          )}
          {uploadedMovie && movieURL && (
            <PlayMovie movieURL={movieURL} vttFileURL={vttURL} />
          )}
        </Col>
      </Row>
    </Card >
  );
};

export default MovieEdit;