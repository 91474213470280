import { LoadingOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import Title from 'antd/lib/typography/Title';
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { commonDateTimeFormat } from "../../constants/common";
import { ExamEntity } from "../../generated-api";
import { apiClientSelector } from "../../selectors/api";
import { authControllerMeSelector } from "../../selectors/auth.api";

const TestContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TakeTestButton = styled(Button)`
  margin: 16px;
  padding: 16px;
  padding-bottom: 40px;
  width: 200px;
  margin-bottom: 32px;
`;

const ResultTestButton = styled(Button)`
  /* width: 250px; */
  margin-bottom: 8px;
`;

type TestResultProps = {
  examId: string;
}

const TestResults = ({ examId }: TestResultProps) => {
  const api = useRecoilValue(apiClientSelector);
  const me = useRecoilValue(authControllerMeSelector);
  const navigate = useNavigate();

  const { loading, value: examResults } = useAsync(async () => {
    if (!me) {
      return;
    }

    const result = await api.examResultControllerGetExamResultsByUserExamId(`${me.userId}/${examId}`);
    return result.data;
  }, [me]);

  if (loading) {
    return <LoadingOutlined />
  }

  if (examResults?.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: '16px', textAlign: "center" }}>
      <Title level={3}>テスト結果一覧</Title>
      {examResults?.map((result) => {
        return (
          <ResultTestButton onClick={() => {
            navigate(`/test_result/${result.exam_result_id}`);
          }}>
            受験日時：{format(new Date(result.created_at), commonDateTimeFormat)}
          </ResultTestButton>
        );
      })}
    </div>
  );
}

type RouterParams = {
  courseId: string;
  unitId: string;
  contentId: string;
}

const PrepareTestTop = () => {
  const params = useParams<RouterParams>();
  const api = useRecoilValue(apiClientSelector);

  const navigate = useNavigate();
  const { courseId, unitId } = params;
  const [exams, setExams] = useState<ExamEntity[]>([]);

  const { loading, error } = useAsync(async () => {
    if (!unitId) {
      return;
    }

    const result = await api.examControllerGetExamByUnitId(unitId);
    setExams(result.data);
  }, [unitId]);

  if (loading || error) {
    return <Skeleton />
  }

  if (!loading && exams.length === 0) {
    return (
      <TestContainer>
        この単元にはテストが作られていません
        <Button
          style={{ marginTop: '32px' }}
          onClick={() => navigate(`/course/${courseId}`)}>
          単元一覧へ戻る
        </Button>
      </TestContainer>
    );
  }

  return (
    <TestContainer>
      <Button
        style={{ marginBottom: '32px' }}
        onClick={() => navigate(`/course/${courseId}`)}>
        単元一覧へ戻る
      </Button>
      {exams[0]?.is_open && (
        <TakeTestButton size="large" onClick={() => {
          navigate(`/course/${courseId}/unit/${unitId}/test/${exams[0].exam_id}`);
        }}>
          テストを受ける
        </TakeTestButton>
      )}
      {!exams[0]?.is_open && "テストは現在、非公開となっています"}
      <TestResults examId={exams[0].exam_id} />
    </TestContainer>
  );
}

export default PrepareTestTop;
