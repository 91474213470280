import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { Button, Card, Divider, Form, message, Radio, Rate, Skeleton, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { PageTitle } from '../../components/PageTitle';
import ResultComments from '../../components/ResultComments';
import { ExamResultStatus, ProblemType, selectionSymbol } from '../../constants/exam';
import { ExamResultEntity, GetExamProblemResponse, StudentAnswerEntity } from '../../generated-api';
import { apiClientSelector } from '../../selectors/api';
import { unitSelector } from '../../selectors/unit.api';
import { userNameSelector } from '../../selectors/user.api';

const BoldText = styled.div`
  font-weight: bold;
`;

type SelectionAnswer = {
  text: string;
  answer: boolean;
  studentAnswer: boolean;
}

type TestResultHeaderProps = {
  userId: string;
  unitId: string;
};

const TestResultHeader = ({ userId, unitId }: TestResultHeaderProps) => {
  const userName = useRecoilValue(userNameSelector(userId));
  const learningUnit = useRecoilValue(unitSelector(unitId));

  if (!userName || !learningUnit) {
    return <LoadingOutlined />
  }

  return (
    <>
      <div style={{ borderBottom: '1px solid #999' }}>
        <PageTitle title={`${learningUnit.title} テスト結果`} />
      </div>
      <div style={{ marginBottom: '20px' }}>
        {userName}
      </div>
    </>
  );
}

type CorrectAnswerProps = {
  selectionAnswers: SelectionAnswer[];
};

const CorrectAnswer = ({ selectionAnswers }: CorrectAnswerProps) => {

  const correct = useMemo(() => {
    return selectionAnswers.filter((item) => item.answer !== item.studentAnswer).length === 0
  }, [selectionAnswers]);

  if (correct) {
    return <>正解</>;
  }

  return (
    <div>正解は
      {selectionAnswers.reduce((result, current, index) => {
        if (current.answer) {
          return [...result, selectionSymbol[index]]
        }
        return result;
      }, [] as string[]).join(",")}
    </div>
  );
}

type ProblemContentProps = {
  name: number;
  restField: {
    fieldKey?: number | undefined;
  };
  remove: (index: number | number[]) => void;
};

const ProblemContent = (props: ProblemContentProps) => {
  const { name, restField, remove } = props;

  return (
    <div style={{ marginBottom: '24px' }}>
      <Title level={4}>設問{name + 1}</Title>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.problems[name]?.type !== currentValues.problems[name]?.type
        }
      >
        {({ getFieldValue }) =>
          // eslint-disable-next-line no-unused-expressions
          getFieldValue(['problems', name, 'type']) === ProblemType.Selection ? (
            <Card style={{ backgroundColor: 'white' }} bodyStyle={{ padding: "12px" }}>
              <BoldText>問題文</BoldText>
              {getFieldValue(['problems', name, 'selectText'])}
              <ProblemDivider />
              <Space direction="vertical">
                {getFieldValue(['problems', name, 'selectionsAndAnswers']).map((item: SelectionAnswer, index: number) => {

                  if (!item.studentAnswer) {
                    return (
                      <div key={selectionSymbol[index]}>{selectionSymbol[index]}: {item.text}</div>
                    )
                  }
                  return (
                    <div key={selectionSymbol[index]} style={{ fontWeight: 700, borderBottom: '1px solid #000' }}>{selectionSymbol[index]}: {item.text}</div>
                  );
                })}
              </Space>
              <ProblemDivider />
              <CorrectAnswer selectionAnswers={getFieldValue(['problems', name, 'selectionsAndAnswers'])} />
              <ProblemDivider />
              <BoldText>評価</BoldText>
              <Form.Item
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restField}
                name={[name, 'score']}>
                <Rate defaultValue={getFieldValue(['problems', name, 'score'])} character={<StarFilled style={{ width: '10px' }} />} />
              </Form.Item>
              <BoldText>コメント</BoldText>
              <Form.Item
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restField}
                name={[name, 'feedback']}>
                <TextArea rows={3} placeholder="フィードバックコメントを入力" />
              </Form.Item>
            </Card>
          ) : (
            <Card style={{ backgroundColor: 'white' }} bodyStyle={{ padding: "12px" }}>
              <BoldText>問題文</BoldText>
              {getFieldValue(['problems', name, 'freeText'])}
              <ProblemDivider />
              <BoldText>解答</BoldText>
              {getFieldValue(['problems', name, 'freeTextAnswer'])}

              <ProblemDivider />

              <BoldText>評価</BoldText>
              <Form.Item name={[name, 'score']}>
                <Rate defaultValue={getFieldValue(['problems', name, 'score'])} character={<StarFilled style={{ width: '10px' }} />} />
              </Form.Item>

              <BoldText>コメント</BoldText>
              <Form.Item name={[name, 'feedback']}>
                <TextArea rows={3} placeholder="フィードバックコメントを入力" />
              </Form.Item>
            </Card>
          )
        }
      </Form.Item>
    </div>
  );
};

type EditProblem = {
  type: ProblemType;
  selectText: string;
  freeText: string;
  selections: { text: string; answer: boolean }[];
  score: number | undefined;
  feedback: string | undefined;
  correct: boolean;
}

type FormValueType = {
  problems: EditProblem[];
  totalResult: string,
  totalFeedback: string | undefined,
  totalScore: number | undefined,
}

type ResultsProps = {
  examResult: ExamResultEntity;
  exam: GetExamProblemResponse;
  answers: StudentAnswerEntity[];
}

const Results = ({ exam, examResult, answers }: ResultsProps) => {
  const api = useRecoilValue(apiClientSelector);

  const initProblems = useMemo(() => {
    return exam.problems.map((item, problemNo) => {
      const answer = answers[problemNo].selection_number ?? [];
      const selections: SelectionAnswer[] = item?.selections?.map((value, index) => {

        return {
          text: value,
          answer: item?.selection_answer ? item?.selection_answer[index] : false,
          studentAnswer: answer.length > 0 ? answer[index] : false,
        };
      }) ?? [];

      const correct = answers[problemNo].selection_number?.every((value, index) => {
        return item?.selection_answer ? item?.selection_answer[index] === value : false
      })

      const defaultScore = answers[problemNo]?.score ?? (selections.length > 0 && correct ? 5 : 0);

      return {
        type: selections.length > 0 ? ProblemType.Selection : ProblemType.FreeText,
        freeText: selections.length > 0 ? '' : item.text,
        selectText: selections.length > 0 ? item.text : '',
        selectionsAndAnswers: selections,
        freeTextAnswer: answers[problemNo]?.free_text ?? "未解答",
        score: defaultScore,
        feedback: answers[problemNo]?.feedback_text,
      };
    });
  }, [answers, exam.problems]);

  const [form] = useForm<FormValueType>();


  useEffect(() => {
    form.setFieldsValue({
      problems: initProblems,
      totalResult: examResult.total_result,
      totalFeedback: examResult.total_feedback,
      totalScore: examResult.total_score,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initProblems, examResult]);

  const finishScoreResults = useCallback(async (formValue: FormValueType) => {
    await api.examResultControllerUpdateExamResultScoreAndFeedback({
      examResultId: examResult.exam_result_id,
      scoreAndFeedback: formValue.problems.map((item, index) => {
        return {
          examProblemId: `${exam.exam_id}/${index}`,
          score: item.score ?? 0,
          feedbackText: item.feedback ?? "",
        }
      }),
    });

    await api.examResultControllerUpdateExamResultTotalScoreAndFeedback({
      examResultId: examResult.exam_result_id,
      totalResult: formValue.totalResult ?? ExamResultStatus.Ungraded,
      totalFeedback: formValue.totalFeedback ?? "",
      totalScore: formValue.totalScore ?? 0,
    });

    message.success("評価およびフィードバックが更新されました。");
  }, [api, exam.exam_id, examResult.exam_result_id]);

  return (
    <Form form={form} colon={false} onFinish={finishScoreResults} labelCol={{ span: 3 }}>
      <Form.List name="problems">
        {(problems, { remove }): JSX.Element => (
          <>
            {problems.map(({ key, name, ...restField }) => (
              <ProblemContent name={name} restField={restField} remove={remove} key={key} />
            ))}
          </>
        )}
      </Form.List>
      <Card style={{ backgroundColor: 'white' }} bodyStyle={{ padding: "12px" }}>
        <BoldText>合否</BoldText>
        <Form.Item
          name={['totalResult']}
        >
          <Radio.Group>
            <Space>
              <Radio value={ExamResultStatus.Pass}>合格</Radio>
              <Radio value={ExamResultStatus.Rejection}>不合格</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <BoldText>総合評価</BoldText>
        <Form.Item name='totalScore'>
          <Rate defaultValue={form.getFieldValue(['total_score'])} character={<StarFilled style={{ fontSize: '30px', width: '24px' }} />} />
        </Form.Item>
        <BoldText>コメント</BoldText>
        <Form.Item name='totalFeedback'>
          <TextArea rows={3} placeholder="フィードバックコメントを入力" />
        </Form.Item>
      </Card>
      <Button
        htmlType="submit"
        type="primary"
        style={{ marginTop: '12px', marginBottom: '12px', }}
      >
        評価を保存する
      </Button>
    </Form>
  );
}

type RouterParams = {
  examId: string;
  examResultId: string;
}

const ScoreExamResult = () => {
  const params = useParams<RouterParams>();
  const { examId, examResultId } = params;

  const api = useRecoilValue(apiClientSelector);
  const { loading, value, error } = useAsync(async () => {
    if (!examResultId || !examId) {
      return;
    }

    const [examResults, examProblems] = await Promise.all([
      api.examResultControllerGetExamResults(examResultId),
      api.examControllerGetExamAndProblems(examId),
    ]);

    return {
      examResult: examResults.data,
      answers: examResults.data.answers,
      exam: examProblems.data
    };
  }, [examResultId, examId]);

  if (loading || error || !value) {
    return <Skeleton />
  }

  return (
    <>
      <TestResultHeader unitId={value.exam.learning_unit_id} userId={value.examResult.user_exam_id.split('/')[0]} />
      <Results examResult={value.examResult} answers={value.answers} exam={value.exam} />
      <ResultComments examResultId={value.examResult.exam_result_id} />
    </>
  );
};

export default ScoreExamResult;


const ProblemDivider = styled(Divider)`
  margin: 8px;
`;
