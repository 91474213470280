import {
  LoadingOutlined,
  PartitionOutlined,
  ReadOutlined,
  SolutionOutlined,
  TableOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { useCallback } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { siderColor } from '../constants/color';
import { UserRole } from '../generated-api';
import { useAuthMethods } from '../selectors/auth';
import { authControllerMeSelector } from '../selectors/auth.api';

type DropDownProps = {
  name: string;
};

const UserDropdown: React.FC<DropDownProps> = (props: DropDownProps) => {
  const { name } = props;
  const [_, logout] = useAuthMethods();
  const navigate = useNavigate();

  const logoutCallback = useCallback(() => {
    logout();
    navigate('/login');
  }, [logout, navigate]);

  const menu = (
    <Menu style={{ minWidth: '200px', padding: '10px 10px' }}>
      <Menu.Item key="/logout" onClick={() => logoutCallback()}>
        ログアウト
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" arrow>
      <Button icon={<UserOutlined />}>{name}</Button>
    </Dropdown>
  );
};

export const DefaultLayout: React.FC = () => {
  const navigate = useNavigate();
  const me = useRecoilValue(authControllerMeSelector);

  if (!me) {
    return <LoadingOutlined />;
  }
  const sideBarItem = [
    {
      key: '/',
      icon: <SolutionOutlined />,
      label: 'マイページ',
    },
    {
      key: '/course',
      icon: <PartitionOutlined />,
      label: 'コースマップ',
    },
    {
      key: '/learning_progress',
      icon: <ReadOutlined />,
      label: '学習進捗',
    },
  ];
  if (me.role === UserRole.Admin) {
    sideBarItem.push(
      {
        key: '/member',
        icon: <UsergroupAddOutlined />,
        label: 'メンバー管理',
      },
      {
        key: '/contents_management',
        icon: <TableOutlined />,
        label: 'コンテンツ管理',
      }
    );
  }

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex' }}>
      <Layout.Header style={{ background: 'white', borderBottom: '1px solid #f0f0f0', padding: '0px 20px', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Link to="/">
          <img src="/logo.svg" alt="enablement" width={130} height={60} />
        </Link>
        <UserDropdown name={me.name} />
      </Layout.Header>
      <Layout style={{ flex: 1, flexGrow: 1 }}>
        <Layout.Sider width={250} style={{ background: siderColor, borderRight: '1px solid #f0f0f0' }}>
          <SideMenu
            style={{ borderRight: 0, background: siderColor }}
            onSelect={(event) => {
              navigate(event.key);
            }}
            mode="inline"
            defaultSelectedKeys={[]}
            items={sideBarItem}
          />
        </Layout.Sider>
        <Layout.Content style={{ background: '#F7F7F7', padding: '20px 40px' }}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const SideMenu = styled(Menu)`
  .ant-menu-item {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 48px;
    line-height: 48px;
    color: #1E633C;
  }
  .ant-menu-item-selected {
    background-color: #EFFEF5;
    color: #1E633C;
  }
  .ant-menu-item-active {
    color: #1E633C !important;
  }
  .ant-menu-item::after {
    border-color: #35B06A;
  }
`