import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { contentMaxWidth, contentMinWidth } from "../../constants/content";
import { apiClientSelector } from '../../selectors/api';
import ContentTitleEdit from "./content-components/ContentTitleEdit";
import MovieEdit from './content-components/MovieEdit';
import TextContentEdit from './content-components/TextContentEdit';

type RouterParams = {
  courseId: string;
  unitId: string;
  contentId: string;
}

const TitleHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const ContentPageWrapper = styled.div`
  max-width: ${contentMaxWidth};
  min-width: ${contentMinWidth};
`;

export const ContentEditorWrapper = styled.div`
  background: #fff;

  margin: 4px;
  padding: 8px;

  max-width: ${contentMaxWidth};
  min-width: ${contentMinWidth};
`;


const ContentEdit = () => {
  const params = useParams<RouterParams>();
  const navigate = useNavigate();
  const { courseId, unitId, contentId } = params;

  const api = useRecoilValue(apiClientSelector);

  const content = useAsync(async () => {
    if (!contentId) {
      return;
    }

    const data = await api.contentControllerGetContentById(contentId);
    return data;
  }, [contentId]);

  if (content.loading || !content.value || !courseId || !unitId) {
    return null;
  }

  return (
    <ContentPageWrapper>
      <Button
        style={{ marginBottom: '16px' }}
        onClick={() => navigate(`/contents_management/course/${courseId}/unit/${unitId}`)}>
        コンテンツ一覧へ戻る
      </Button>
      <TitleHeader>
        <ContentTitleEdit content={content.value.data} />
      </TitleHeader>
      <ContentEditorWrapper>
        <MovieEdit contentId={content.value.data.content_id} />
      </ContentEditorWrapper>
      <ContentEditorWrapper>
        <TextContentEdit targetContent={content.value.data} />
      </ContentEditorWrapper>
    </ContentPageWrapper>
  );
};



export default ContentEdit;