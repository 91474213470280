/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
import { Button } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import videojs from 'video.js';
import "video.js/dist/video-js.css";

const videoNodeId = 'videojs-player';

const ReloadButton = styled(Button)`
  margin: 4px;
  margin-left: 0px;
`;

type Props = {
  movieURL: string,
  vttFileURL: string | null,
}

const PlayMovie = (props: Props) => {
  const { movieURL, vttFileURL } = props;
  const [player, setPlayer] = useState<videojs.Player>();

  const reload = () => {
    if (!player) {
      return;
    }
    // 字幕を再定義
    player.addRemoteTextTrack({
      kind: 'captions',
      src: vttFileURL ?? '',
      label: 'new',
    }, false);
  }

  useEffect(() => {
    if (!movieURL) {
      return;
    }

    setPlayer(videojs(videoNodeId, {
      controls: true,
      muted: false,
      sources: [{
        src: movieURL,
        type: 'application/x-mpegURL'
      }],
      width: 750,
      height: 600,
      fluid: true,
      tracks: [{
        kind: 'captions',
        default: true,
        src: vttFileURL ?? '',
        label: 'current',
      }],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movieURL]);

  useEffect(() => {
    return function cleanup() {
      if (player) {
        player.dispose();
      }
    };
  });

  return (
    <>
      <div className="c-player">
        <div className="c-player__screen" data-vjs-player="true">
          <video
            id={videoNodeId}
            className="video-js"
            crossOrigin="anonymous"
          />
        </div>
      </div>
      <ReloadButton onClick={() => reload()}>動画を再読み込みする</ReloadButton>
    </>
  );
};

export default PlayMovie;
