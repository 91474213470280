import axios from 'axios';
import { decodeJwt, JWTPayload } from 'jose';
// eslint-disable-next-line camelcase
import { sha3_512 } from 'js-sha3';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { atom, Loadable, noWait, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { Configuration, DefaultApiFactory } from '../generated-api';
import { localStorageEffect, timeAtom } from './util';

const refreshTokenCsrfGuardAtom = atom<string | undefined>({
  key: 'refreshTokenCsrfGuard',
  default: undefined,
  effects: [localStorageEffect('refresh_token_csrf_guard')],
});

const rawAccessTokenSelector = selector<string | undefined>({
  key: 'rawAccessToken',
  get: async ({ get, getCallback }) => {
    const api = DefaultApiFactory(
      new Configuration({
        basePath:
          window.location.host.indexOf('localhost') === -1
            ? process.env.REACT_APP_API_ENDPOINT
            : 'http://localhost:5000',
        baseOptions: {
          withCredentials: true,
        },
      })
    );
    const refreshTokenCsrfGuard = get(refreshTokenCsrfGuardAtom);
    if (!refreshTokenCsrfGuard) {
      return undefined;
    }
    try {
      const accessTokenResult = await api.authControllerAccessToken(
        {
          refresh_token_csrf_guard: refreshTokenCsrfGuard,
        },
        { withCredentials: true }
      );
      return accessTokenResult.data.access_token;
    } catch (e) {
      console.error(e);

      if (axios.isAxiosError(e) && e.response && e.response.status === 401) {
        const resetRefreshToken = getCallback(({ reset }) => () => {
          console.error('will reset refreshTokenCsrfGuardAtom');
          reset(refreshTokenCsrfGuardAtom);
        });
        setTimeout(resetRefreshToken, 1);
      }

      return undefined;
    }
  },
});

const parsedAccessTokenSelector = selector<JWTPayload | undefined>({
  key: 'parsedAccessTokenSelector',
  get: async ({ get }) => {
    const access_token = get(rawAccessTokenSelector);
    if (!access_token) {
      return undefined;
    }
    const parsed = decodeJwt(access_token);
    return parsed;
  },
});

let newAccessTokenPromise: Promise<string | undefined> | undefined;

const validAccessTokenSelector = selector<string | undefined>({
  key: 'validAccessToken',
  get: async ({ get, getCallback }) => {
    const access_token: string | undefined = get(rawAccessTokenSelector);
    const parsed = get(parsedAccessTokenSelector);
    const now = get(timeAtom);
    if (!parsed?.exp) {
      console.log('access token not available');
      return undefined;
    }
    if (parsed?.exp < now + 30) {
      console.log('access token is expiring');
      if (!newAccessTokenPromise) {
        console.log('will reclaim access token');
        const refreshAccessToken = getCallback(({ refresh, snapshot }) => async () => {
          refresh(rawAccessTokenSelector);
          const acccess_token: Loadable<string | undefined> = await snapshot.getPromise(noWait(rawAccessTokenSelector));
          return acccess_token.promiseMaybe();
        });
        newAccessTokenPromise = new Promise<string | undefined>((resolve, reject) => {
          setTimeout(() => {
            refreshAccessToken().then(resolve).catch(reject);
          }, 1);
        });
        newAccessTokenPromise.finally(() => {
          console.log('access token reclaimed??');
          newAccessTokenPromise = undefined;
        });
      }
      return access_token;
    }
    if (parsed?.exp < now) {
      console.log('access token expired');
      if (newAccessTokenPromise) {
        console.log('return newAccessTokenPromise (which will activate react suspense loading)');
        return newAccessTokenPromise;
      }
      return undefined;
    }
    // console.debug("access token ttl", parsed.exp - now);
    return access_token;
  },
});

export const userIdSelector = selector({
  key: 'userId',
  get: ({ get }) => {
    const userId = get(parsedAccessTokenSelector)?.user_id;
    return userId as string | undefined;
  },
});

export const accessTokenResolver = selector<() => string | undefined>({
  key: 'accessTokenResolver',
  get: ({ get, getCallback }) => {
    const userId = get(userIdSelector);

    if (userId) {
      return getCallback(({ snapshot }) => () => {
        return snapshot.getLoadable(validAccessTokenSelector).getValue();
      });
    }
    return () => undefined;
  },
});

const loggedInSelector = selector({
  key: 'loggedIn',
  get: ({ get }) => !!get(validAccessTokenSelector),
});

export const useIsLoggedIn = () => {
  const [state, setState] = useState(true);
  const v = useRecoilValue(noWait(loggedInSelector));
  useEffect(() => {
    if (v.state === 'loading') {
      // nop
    } else if (v.state === 'hasError') {
      setState(false);
    } else {
      setState(v.contents);
    }
  }, [v.contents, v.state]);
  return state;
};

export const RequireAuth: React.VFC = () => {
  const loggedIn = useIsLoggedIn();
  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

export const useAuthMethods = () => {
  const login = useRecoilCallback(
    ({ set }) =>
      async (email: string, password: string) => {
        const api = DefaultApiFactory(
          new Configuration({
            basePath:
              window.location.host.indexOf('localhost') === -1
                ? process.env.REACT_APP_API_ENDPOINT
                : 'http://localhost:5000',
            baseOptions: {
              withCredentials: true,
            },
          })
        );
        const result = await api.authControllerRefreshToken(
          {
            email,
            password: sha3_512(`791e83c4-2b55-4bbd-ba94-cb6f0773f2b3${password}`),
          },
          { withCredentials: true }
        );
        set(refreshTokenCsrfGuardAtom, result.data.refresh_token_csrf_guard);
      },
    []
  );
  const logout = useRecoilCallback(
    ({ reset }) =>
      async () => {
        reset(refreshTokenCsrfGuardAtom);
      },
    []
  );

  return [login, logout] as const;
};

export const usePasswordReset = () => {
  const requestPasswordResetLink = useRecoilCallback(
    () =>
      async (email: string) => {
        const api = DefaultApiFactory(
          new Configuration({
            basePath: window.location.host.indexOf("localhost") === -1 ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:5000",
            baseOptions: {
              withCredentials: true
            }
          })
        );
        await api.accountControllerSendPasswordResetLink({ email });
      },
    []
  );
  const resetPassword = useRecoilCallback(
    () =>
      async (token: string, password: string): Promise<string> => {
        const api = DefaultApiFactory(
          new Configuration({
            basePath: window.location.host.indexOf("localhost") === -1 ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:5000",
            baseOptions: {
              withCredentials: true
            }
          })
        );
        return (await api.accountControllerResetPassword({
          token, password: sha3_512(
            `791e83c4-2b55-4bbd-ba94-cb6f0773f2b3${password}`
          )
        })).data;
      },
    []
  );
  return [requestPasswordResetLink, resetPassword] as const;
}