import { Button, Col, message, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useRecoilValue } from "recoil";
import styled from 'styled-components';
import MoveButton from '../../components/MoveButton';
import { PageTitle } from '../../components/PageTitle';
import { MovieEntity } from '../../generated-api';
import { apiClientSelector } from '../../selectors/api';
import { ContentEditorWrapper, ContentPageWrapper } from '../contentsManagement/ContentEdit';
import MovieContent from './content-components/MovieContent';
import TextContent from './content-components/TextContent';

const ContentMarker = styled.div`
  margin-left: 16px;
  min-width: 250px;
  max-width: 250px;
`;

const ContentTitle = styled.div<{ target?: boolean }>`
  line-height: 1.5715;
  position: relative;
  margin: 0;
  overflow-wrap: break-word;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-left: ${(props => props.target ? '8px solid #40b0a9' : '1px solid #d9d9d9')};
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  padding: 4px 15px;
  font-size: 14px;
  color: #1e633c;
  background: #fff;

  &:hover {
    color: #1e633c;
  }

  &:last-child {
    border-bottom: 1px solid #ddd;
  }
`;

type RouterParams = {
  courseId: string;
  unitId: string;
  contentId: string;
}

const ContentTop = () => {
  const params = useParams<RouterParams>();
  const { contentId, unitId, courseId } = params;
  const [movieExistence, setMovieExistence] = useState<boolean>(false);
  const [movie, setMovie] = useState<MovieEntity>();

  const basePath = `/course/${courseId}`;

  const navigate = useNavigate();
  const api = useRecoilValue(apiClientSelector);

  const [nextPage, setNextPage] = useState<{
    label: string;
    link: string;
  }>();

  const [previousPage, setPreviousPage] = useState<{
    label: string;
    link: string;
  }>();

  const { loading, error, value } = useAsync(async () => {
    if (!contentId || !unitId) {
      return;
    }

    const [contents, targetContent, targetMovie, test] = await Promise.all([
      api.contentControllerGetContentsByUnitId(unitId),
      api.contentControllerGetContentById(contentId),
      api.movieControllerGetMovieByContentId(contentId),
      api.examControllerGetExamByUnitId(unitId)
    ]);

    const currentIndex = contents.data.findIndex((item) => {
      return targetContent.data.content_id === item.content_id;
    });

    if (currentIndex === 0 || contents.data.length === 1) {
      setPreviousPage({
        label: '単元一覧へ',
        link: basePath,
      });
    } else {
      setPreviousPage({
        label: contents.data[currentIndex].title,
        link: `${basePath}/unit/${unitId}/content/${contents.data[currentIndex - 1].content_id}`,
      });
    }

    if (currentIndex === contents.data.length - 1) {
      if (test.data) {
        setNextPage({
          label: 'テスト',
          link: `${basePath}/unit/${unitId}/prepare-test`,
        });
      } else {
        setNextPage({
          label: '単元を終了する',
          link: basePath,
        });
      }
    } else {
      setNextPage({
        label: contents.data[currentIndex + 1].title,
        link: `${basePath}/unit/${unitId}/content/${contents.data[currentIndex + 1].content_id}`
      });
    }

    setMovie(targetMovie.data);

    return {
      contents: contents.data,
      targetContent: targetContent.data,
      test: test.data
    }
  }, [contentId, unitId]);

  useEffect(() => {
    if (!movie || !api) {
      setMovieExistence(false);
      return;
    }

    (async () => {
      // バケットの確認 (バケットの確認後、問題無ければ、URLを返却。NotFoundの場合はError)
      const url = (await api.movieControllerCheckMovieExistence(movie?.movie_id)).data;

      // URLの確認
      const check = await fetch(url, {
        method: 'GET',
      });

      if (check.ok) {
        setMovieExistence(true);
      }
    })();
  }, [api, movie]);

  if (loading) {
    return <>Loading...</>
  }

  if (error || !value?.targetContent) {
    return null;
  }

  const updateProgress = async (link: string) => {
    try {
      setMovieExistence(false);
      await api.progressControllerCreateProgress({ contentId: value?.targetContent.content_id });
      navigate(link);
    } catch (e) {
      console.log(e);
      message.error("進捗更新に失敗しました");
    }
  };

  // eslint-disable-next-line no-shadow
  const toOtherContent = async (contentId: string) => {
    const isAccessible = await api.contentControllerIsAccessibleToContent(contentId)
    if (isAccessible.data) {
      updateProgress(`${basePath}/unit/${unitId}/content/${contentId}`);
    } else {
      message.error("コンテンツ順に受講してください");
    }
  };

  const toTestPage = async () => {
    const isAccessible = await api.contentControllerIsAccessibleToTest(String(unitId))
    if (isAccessible.data) {
      navigate(`/course/${courseId}/unit/${unitId}/prepare-test`)
    } else {
      message.error("未受講のコンテンツがあります")
    }
  };

  return (
    <>
      <PageTitle title={value.targetContent.title ?? ''} mb="16" />
      <div style={{ display: 'flex', flexDirection: 'row' }} >
        <ContentPageWrapper>
          {movieExistence && movie && (
            <ContentEditorWrapper>
              <MovieContent movie={movie} />
            </ContentEditorWrapper>
          )}
          <ContentEditorWrapper>
            {!value.targetContent.content_json && "コンテンツが存在しません"}
            {value.targetContent.content_json && <TextContent targetContent={value.targetContent} />}
          </ContentEditorWrapper>
          <Row gutter={32} style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
            <Col span={8} style={{ minHeight: '100px' }}>
              {previousPage && <MoveButton label={previousPage.label} onClick={() => navigate(previousPage.link)} />}
            </Col>
            <Col span={8} style={{ minHeight: '100px' }}>
              {nextPage && nextPage.link && <MoveButton forward label={nextPage.label} onClick={() => updateProgress(nextPage.link)} />}
            </Col>
          </Row>
        </ContentPageWrapper>
        <ContentMarker style={{ display: 'flex', flexDirection: 'column', fontSize: '20px' }}>
          <Button style={{ marginBottom: '16px' }} onClick={() => navigate(basePath)}>単元一覧へ</Button>
          <div style={{ fontSize: '14px', marginBottom: "8px", fontWeight: '700' }}>コンテンツ一覧</div>
          {value.contents.map((item) => {
            return (
              <ContentTitle
                key={item.content_id}
                target={item.content_id === value.targetContent.content_id}
                onClick={() => toOtherContent(item.content_id)}
              >
                {item.title}
              </ContentTitle>
            );
          })}
          <div style={{ fontSize: '14px', marginTop: "16px", marginBottom: "8px", fontWeight: '700' }}>テスト</div>
          {value.test ?
            <ContentTitle
              target={false}
              onClick={() => toTestPage()}
            >
              テスト
            </ContentTitle> :
            <Typography.Text style={{ fontSize: "16px" }}>この単元にテストはありません</Typography.Text>}
        </ContentMarker>
      </div>
    </>
  );
};

export default ContentTop;
