import { selector } from 'recoil';
import { apiClientSelector } from "./api";

export const authControllerMeSelector = selector({
  key: "meSelector",
  get: async ({ get }) => {
    const client = get(apiClientSelector);
    try {
      const result = await client.authControllerMe();
      return result.data;
    } catch (error) {
      return undefined;
    }
  },
});