import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { format } from "date-fns";
import { ChangeEvent, useCallback, useState } from "react";
import { useAsync } from "react-use";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { commonDateTimeFormat } from "../constants/common";
import { ExamResultCommentEntity } from "../generated-api";
import { apiClientSelector } from "../selectors/api";
import { authControllerMeSelector } from "../selectors/auth.api";
import { userNameSelector } from "../selectors/user.api";

const { Title } = Typography;

type DisplayComment = Omit<ExamResultCommentEntity, 'company_id' | 'exam_result_id'>;

type CommentProps = {
  comment: DisplayComment;
}

const Comment = ({ comment }: CommentProps) => {
  const userName = useRecoilValue(userNameSelector(comment.user_id));

  if (!userName) {
    return <LoadingOutlined />;
  }

  return (
    <Card title={userName} extra={format(new Date(comment.created_at), commonDateTimeFormat)} style={{ marginTop: '8px' }} headStyle={{ padding: "0px 12px" }} bodyStyle={{ padding: "12px" }}>
      {comment.comment_text}
    </Card>
  );
}


type Props = {
  examResultId: string;
}

const ResultComments = ({ examResultId }: Props) => {
  const api = useRecoilValue(apiClientSelector);
  const me = useRecoilValue(authControllerMeSelector);

  const [comments, setComments] = useState<ExamResultCommentEntity[]>([]);
  const [inputComment, setInputComment] = useState<string>("");
  const [addedComments, setAddedComments] = useState<DisplayComment[]>([]);

  const onChangeComment = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputComment(event.target.value);
  }, []);

  const addComment = useCallback(async () => {
    if (!me) {
      return;
    }
    api.examResultCommentControllerCreateComment({
      examResultId,
      comment: inputComment,
    });

    setAddedComments(current => [...current, {
      comment_id: (new Date).toISOString(),
      user_id: me.userId,
      comment_text: inputComment,
      created_at: format(new Date(), commonDateTimeFormat),
    }]);

    setInputComment('');
  }, [api, examResultId, inputComment, me]);

  useAsync(async () => {
    const result = await api.examResultCommentControllerGetComments(examResultId);
    setComments(result.data);
  }, []);

  if (!me) {
    return <LoadingOutlined />
  }

  return (
    <CommentRoot>
      <Title level={3}>コメント</Title>
      {comments.map((comment) => {
        return (
          <Comment key={comment.comment_id} comment={comment} />
        );
      })}
      {addedComments.map((comment) => {
        return (
          <Comment key={comment.comment_id} comment={comment} />
        )
      })}
      <InputCommentCard>
        {/* <BoldText>コメント</BoldText><br /> */}
        <TextArea rows={3} placeholder="コメントを入力" value={inputComment} onChange={onChangeComment} />
        <Button style={{ marginTop: '8px' }} onClick={addComment}>コメントを投稿する</Button>
      </InputCommentCard>
    </CommentRoot>
  );
};

export default ResultComments;

const InputCommentCard = styled(Card)`
  margin-top: 24px;
  bodyStyle={ padding: "12px"; }
`;

const CommentRoot = styled.div`
  margin-top: 16px;
`
