import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';

type Props = {
  label: string;
  forward?: boolean;
  width?: string;
  onClick: () => void;
}

const MoveButton = ({ label, width = '100%', forward = false, onClick }: Props) => {
  return (

    <Card bodyStyle={{ padding: '14px', minHeight: '3rem' }} hoverable onClick={onClick}>
      <Row gutter={16} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {forward && (
          <>
            <Col span={20}>
              <div style={{ fontSize: '14px', fontWeight: 'bold', overflowWrap: 'break-word' }}>{label}</div>
            </Col>
            <Col span={4}>
              <CaretRightOutlined />
            </Col>
          </>
        )}
        {!forward && (
          <>
            <Col span={4}>
              <CaretLeftOutlined />
            </Col>
            <Col span={20}>
              <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{label}</div>
            </Col>
          </>
        )}
      </Row>
    </Card>
  )
};

export default MoveButton;