import { Button, Form, Input, Modal, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { PageTitle } from "../../../components/PageTitle";
import { ContentEntity } from "../../../generated-api";
import { apiClientSelector } from "../../../selectors/api";

type ModalProps = {
  title: string;
  onUpdate: (title: string) => void;
}

const EditTitleModal = (props: ModalProps) => {
  const { title, onUpdate } = props;
  const [opening, setOpening] = useState(false);
  const [form] = useForm();

  const closeModal = () => {
    setOpening(false);
  }

  return (
    <div>
      <Button
        onClick={() => {
          setOpening(true);
        }}
      >
        タイトル編集
      </Button>
      <Modal
        open={opening}
        onCancel={() => {
          setOpening(false);
        }}
        maskClosable={false}
        closeIcon={false}
        footer={false}
        title="コンテンツタイトル編集"
      >
        <Form
          form={form}
          colon={false}
          labelCol={{ span: 4 }}
          onFinish={(values) => {
            onUpdate(values.title);
            closeModal();
          }}
        >
          {/* eslint-disable-next-line no-template-curly-in-string */}
          <FormItem name="title" label="タイトル" rules={[{ required: true, message: '${label}を入力してください' }]}>
            <Input placeholder="タイトルを入力" defaultValue={title} />
          </FormItem>
          <FormItem
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Space size="large">
              <Button htmlType="button" onClick={closeModal}>
                キャンセル
              </Button>
              <Button htmlType="submit" type="primary">
                更新する
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </div>
  );
};

type Props = {
  content: ContentEntity;
}

const ContentTitleEdit = (props: Props) => {
  const api = useRecoilValue(apiClientSelector);
  const { content } = props;
  const [displayTitle, setDisplayTitle] = useState<string>(content.title);

  return (
    <>
      <PageTitle title={displayTitle} />
      <EditTitleModal title={content.title} onUpdate={async (title) => {
        await api.contentControllerUpdateTitle({
          contentId: content.content_id,
          title,
        });
        setDisplayTitle(title);
      }} />
    </>
  );
}

export default ContentTitleEdit;