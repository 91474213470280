/* eslint-disable no-shadow */

export const enum ProblemType {
  Selection = 'selection',
  FreeText = 'freeText'
}

export const enum ExamResultStatus {
  Ungraded = 'ungraded',
  Pass = 'pass',
  Rejection = 'rejection',
}

export const ExamResultStatusDisplay: { [key: string]: string } = {
  ungraded: '未評価',
  pass: '合格',
  rejection: '不合格',
};


export const selectionSymbol = ['ア', 'イ', 'ウ', 'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ'];
