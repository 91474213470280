/* tslint:disable */
/* eslint-disable */
/**
 * Gigsales Enablement API
 * The Gigsales Enablement API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountPasswordResetInitiateRequest
 */
export interface AccountPasswordResetInitiateRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountPasswordResetInitiateRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AccountPasswordResetRequest
 */
export interface AccountPasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountPasswordResetRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPasswordResetRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface AuthAccessTokenRequest
 */
export interface AuthAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthAccessTokenRequest
     */
    'refresh_token_csrf_guard': string;
}
/**
 * 
 * @export
 * @interface AuthAccessTokenResponse
 */
export interface AuthAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthAccessTokenResponse
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface AuthMeResponse
 */
export interface AuthMeResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthMeResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthMeResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AuthMeResponse
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof AuthMeResponse
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AuthRefreshTokenRequest
 */
export interface AuthRefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshTokenRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshTokenRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthRefreshTokenResponse
 */
export interface AuthRefreshTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshTokenResponse
     */
    'refresh_token_csrf_guard': string;
}
/**
 * 
 * @export
 * @interface ContentEntity
 */
export interface ContentEntity {
    /**
     * company id
     * @type {string}
     * @memberof ContentEntity
     */
    'company_id': string;
    /**
     * content id
     * @type {string}
     * @memberof ContentEntity
     */
    'content_id': string;
    /**
     * コンテンツ内容
     * @type {string}
     * @memberof ContentEntity
     */
    'content_json': string;
    /**
     * course id
     * @type {string}
     * @memberof ContentEntity
     */
    'course_id': string;
    /**
     * learning unit id
     * @type {string}
     * @memberof ContentEntity
     */
    'learning_unit_id': string;
    /**
     * movie id
     * @type {string}
     * @memberof ContentEntity
     */
    'movie_id': string;
    /**
     * ページ数
     * @type {number}
     * @memberof ContentEntity
     */
    'page_no': number;
    /**
     * タイトル
     * @type {string}
     * @memberof ContentEntity
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CountPerUnitByUnitIdsRequest
 */
export interface CountPerUnitByUnitIdsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CountPerUnitByUnitIdsRequest
     */
    'unitIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CourseEntity
 */
export interface CourseEntity {
    /**
     * 組織ID
     * @type {string}
     * @memberof CourseEntity
     */
    'company_id': string;
    /**
     * 紐づくコンテンツ数
     * @type {number}
     * @memberof CourseEntity
     */
    'content_count': number;
    /**
     * course id
     * @type {string}
     * @memberof CourseEntity
     */
    'course_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof CourseEntity
     */
    'created_at': string;
    /**
     * コース説明文
     * @type {string}
     * @memberof CourseEntity
     */
    'description': string;
    /**
     * 学習に必要な時間
     * @type {string}
     * @memberof CourseEntity
     */
    'required_time': string;
    /**
     * コース名
     * @type {string}
     * @memberof CourseEntity
     */
    'title': string;
    /**
     * 紐づく単元数
     * @type {number}
     * @memberof CourseEntity
     */
    'unit_count': number;
    /**
     * 更新日時
     * @type {string}
     * @memberof CourseEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface CourseMapEntity
 */
export interface CourseMapEntity {
    /**
     * 
     * @type {string}
     * @memberof CourseMapEntity
     */
    'company_id': string;
    /**
     * course id
     * @type {string}
     * @memberof CourseMapEntity
     */
    'course_id': string;
    /**
     * 作成日
     * @type {string}
     * @memberof CourseMapEntity
     */
    'created_at': string;
    /**
     * 割り当てフラグ
     * @type {boolean}
     * @memberof CourseMapEntity
     */
    'is_assigned': boolean;
    /**
     * 必須フラグ
     * @type {boolean}
     * @memberof CourseMapEntity
     */
    'is_required': boolean;
    /**
     * user id
     * @type {string}
     * @memberof CourseMapEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface CreateCommentRequest
 */
export interface CreateCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCommentRequest
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentRequest
     */
    'examResultId': string;
}
/**
 * 
 * @export
 * @interface CreateContentRequest
 */
export interface CreateContentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateContentRequest
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContentRequest
     */
    'learningUnitId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateContentRequest
     */
    'pageNo': number;
    /**
     * 
     * @type {string}
     * @memberof CreateContentRequest
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CreateCourseMapRequest
 */
export interface CreateCourseMapRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCourseMapRequest
     */
    'course_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCourseMapRequest
     */
    'is_assigned': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCourseMapRequest
     */
    'is_required': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseMapRequest
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface CreateCourseProgressRequest
 */
export interface CreateCourseProgressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCourseProgressRequest
     */
    'contentId': string;
}
/**
 * 
 * @export
 * @interface CreateCourseRequest
 */
export interface CreateCourseRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCourseRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseRequest
     */
    'requiredTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseRequest
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CreateExamRequest
 */
export interface CreateExamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateExamRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExamRequest
     */
    'learning_unit_id': string;
}
/**
 * 
 * @export
 * @interface CreateExamResultRequest
 */
export interface CreateExamResultRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateExamResultRequest
     */
    'examId': string;
    /**
     * 
     * @type {Array<ProblemAnswer>}
     * @memberof CreateExamResultRequest
     */
    'studentAnswer': Array<ProblemAnswer>;
    /**
     * 
     * @type {string}
     * @memberof CreateExamResultRequest
     */
    'unitId': string;
}
/**
 * 
 * @export
 * @interface CreateImageRequest
 */
export interface CreateImageRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateImageRequest
     */
    'contentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImageRequest
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface CreateImageResponse
 */
export interface CreateImageResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateImageResponse
     */
    'imageId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImageResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface CreateLearningUnitRequest
 */
export interface CreateLearningUnitRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLearningUnitRequest
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLearningUnitRequest
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CreateLearningUnitRequest
     */
    'unitOrder': number;
}
/**
 * 
 * @export
 * @interface CreateMovieRequest
 */
export interface CreateMovieRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMovieRequest
     */
    'contentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMovieRequest
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface CreateTagIdRequestDto
 */
export interface CreateTagIdRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTagIdRequestDto
     */
    'tagTitle': string;
}
/**
 * 
 * @export
 * @interface DeleteCourseRequest
 */
export interface DeleteCourseRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCourseRequest
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface DeleteLearningUnitRequest
 */
export interface DeleteLearningUnitRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteLearningUnitRequest
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLearningUnitRequest
     */
    'learningUnitId': string;
}
/**
 * 
 * @export
 * @interface DeleteTagIdRequestDto
 */
export interface DeleteTagIdRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteTagIdRequestDto
     */
    'tagId': string;
}
/**
 * 
 * @export
 * @interface EditExamRequest
 */
export interface EditExamRequest {
    /**
     * 
     * @type {string}
     * @memberof EditExamRequest
     */
    'examId': string;
    /**
     * 
     * @type {Array<ExamProblemWithoutExamId>}
     * @memberof EditExamRequest
     */
    'problems': Array<ExamProblemWithoutExamId>;
}
/**
 * 
 * @export
 * @interface ExamEntity
 */
export interface ExamEntity {
    /**
     * 組織ID
     * @type {string}
     * @memberof ExamEntity
     */
    'company_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof ExamEntity
     */
    'created_at': string;
    /**
     * 説明文
     * @type {string}
     * @memberof ExamEntity
     */
    'description'?: string;
    /**
     * exam id
     * @type {string}
     * @memberof ExamEntity
     */
    'exam_id': string;
    /**
     * 初回公開フラグ
     * @type {boolean}
     * @memberof ExamEntity
     */
    'is_init_open': boolean;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof ExamEntity
     */
    'is_open'?: boolean;
    /**
     * 単元id
     * @type {string}
     * @memberof ExamEntity
     */
    'learning_unit_id': string;
}
/**
 * 
 * @export
 * @interface ExamProblemEntity
 */
export interface ExamProblemEntity {
    /**
     * exam id
     * @type {string}
     * @memberof ExamProblemEntity
     */
    'exam_id': string;
    /**
     * 問題番号
     * @type {number}
     * @memberof ExamProblemEntity
     */
    'exam_problem_id': number;
    /**
     * 正解の選択肢
     * @type {Array<boolean>}
     * @memberof ExamProblemEntity
     */
    'selection_answer'?: Array<boolean>;
    /**
     * 選択肢
     * @type {Array<string>}
     * @memberof ExamProblemEntity
     */
    'selections'?: Array<string>;
    /**
     * 問題文
     * @type {string}
     * @memberof ExamProblemEntity
     */
    'text'?: string;
    /**
     * 問題のタイトル
     * @type {string}
     * @memberof ExamProblemEntity
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface ExamProblemWithoutExamId
 */
export interface ExamProblemWithoutExamId {
    /**
     * 
     * @type {number}
     * @memberof ExamProblemWithoutExamId
     */
    'exam_problem_id': number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof ExamProblemWithoutExamId
     */
    'selection_answer'?: Array<boolean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExamProblemWithoutExamId
     */
    'selections'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExamProblemWithoutExamId
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ExamProblemWithoutExamId
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface ExamResultCommentEntity
 */
export interface ExamResultCommentEntity {
    /**
     * comment id
     * @type {string}
     * @memberof ExamResultCommentEntity
     */
    'comment_id': string;
    /**
     * コメント文章
     * @type {string}
     * @memberof ExamResultCommentEntity
     */
    'comment_text': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof ExamResultCommentEntity
     */
    'company_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof ExamResultCommentEntity
     */
    'created_at': string;
    /**
     * テスト結果ID
     * @type {string}
     * @memberof ExamResultCommentEntity
     */
    'exam_result_id': string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof ExamResultCommentEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface ExamResultEntity
 */
export interface ExamResultEntity {
    /**
     * 組織ID
     * @type {string}
     * @memberof ExamResultEntity
     */
    'company_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof ExamResultEntity
     */
    'created_at': string;
    /**
     * exam result id
     * @type {string}
     * @memberof ExamResultEntity
     */
    'exam_result_id': string;
    /**
     * このフラグを折ると試験が無効になり、要再受験となる
     * @type {boolean}
     * @memberof ExamResultEntity
     */
    'is_valid': boolean;
    /**
     * 単元ID
     * @type {string}
     * @memberof ExamResultEntity
     */
    'learning_unit_id': string;
    /**
     * 紐づく問題数
     * @type {number}
     * @memberof ExamResultEntity
     */
    'problem_count': number;
    /**
     * 採点した問題数
     * @type {number}
     * @memberof ExamResultEntity
     */
    'score_progress'?: number;
    /**
     * 紐づく問題のスコアの割合 問題数10の場合 スコア合計/(10*5)
     * @type {number}
     * @memberof ExamResultEntity
     */
    'score_rate'?: number;
    /**
     * テスト全体に対するコメント
     * @type {string}
     * @memberof ExamResultEntity
     */
    'total_feedback'?: string;
    /**
     * ungraded:テスト完了（未採点）、pass:合格、rejection:不合格
     * @type {string}
     * @memberof ExamResultEntity
     */
    'total_result': string;
    /**
     * 5段階評価1~5
     * @type {number}
     * @memberof ExamResultEntity
     */
    'total_score'?: number;
    /**
     * ユーザー＆テストID
     * @type {string}
     * @memberof ExamResultEntity
     */
    'user_exam_id': string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof ExamResultEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface GetCompletedCountByUserId
 */
export interface GetCompletedCountByUserId {
    /**
     * 
     * @type {string}
     * @memberof GetCompletedCountByUserId
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface GetCourseAssignRequest
 */
export interface GetCourseAssignRequest {
    /**
     * 
     * @type {string}
     * @memberof GetCourseAssignRequest
     */
    'courseId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseAssignRequest
     */
    'userIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetCourseMapResponseDto
 */
export interface GetCourseMapResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'company_id': string;
    /**
     * course id
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'course_id': string;
    /**
     * 作成日
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'description'?: string;
    /**
     * 割り当てフラグ
     * @type {boolean}
     * @memberof GetCourseMapResponseDto
     */
    'is_assigned': boolean;
    /**
     * 必須フラグ
     * @type {boolean}
     * @memberof GetCourseMapResponseDto
     */
    'is_required': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'requiredTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof GetCourseMapResponseDto
     */
    'unitCount': number;
    /**
     * user id
     * @type {string}
     * @memberof GetCourseMapResponseDto
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface GetCourseProgressRequest
 */
export interface GetCourseProgressRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseProgressRequest
     */
    'courseIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetCourseProgressResponse
 */
export interface GetCourseProgressResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCourseProgressResponse
     */
    'allContentsCount': number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseProgressResponse
     */
    'completedContents': number;
    /**
     * 
     * @type {string}
     * @memberof GetCourseProgressResponse
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface GetExamProblemResponse
 */
export interface GetExamProblemResponse {
    /**
     * 組織ID
     * @type {string}
     * @memberof GetExamProblemResponse
     */
    'company_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof GetExamProblemResponse
     */
    'created_at': string;
    /**
     * 説明文
     * @type {string}
     * @memberof GetExamProblemResponse
     */
    'description'?: string;
    /**
     * exam id
     * @type {string}
     * @memberof GetExamProblemResponse
     */
    'exam_id': string;
    /**
     * 初回公開フラグ
     * @type {boolean}
     * @memberof GetExamProblemResponse
     */
    'is_init_open': boolean;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof GetExamProblemResponse
     */
    'is_open'?: boolean;
    /**
     * 単元id
     * @type {string}
     * @memberof GetExamProblemResponse
     */
    'learning_unit_id': string;
    /**
     * 
     * @type {Array<ExamProblemEntity>}
     * @memberof GetExamProblemResponse
     */
    'problems': Array<ExamProblemEntity>;
}
/**
 * 
 * @export
 * @interface GetExamResultsResponse
 */
export interface GetExamResultsResponse {
    /**
     * 
     * @type {Array<StudentAnswerEntity>}
     * @memberof GetExamResultsResponse
     */
    'answers': Array<StudentAnswerEntity>;
    /**
     * 組織ID
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'company_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'created_at': string;
    /**
     * exam result id
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'exam_result_id': string;
    /**
     * このフラグを折ると試験が無効になり、要再受験となる
     * @type {boolean}
     * @memberof GetExamResultsResponse
     */
    'is_valid': boolean;
    /**
     * 単元ID
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'learning_unit_id': string;
    /**
     * 紐づく問題数
     * @type {number}
     * @memberof GetExamResultsResponse
     */
    'problem_count': number;
    /**
     * 採点した問題数
     * @type {number}
     * @memberof GetExamResultsResponse
     */
    'score_progress'?: number;
    /**
     * 紐づく問題のスコアの割合 問題数10の場合 スコア合計/(10*5)
     * @type {number}
     * @memberof GetExamResultsResponse
     */
    'score_rate'?: number;
    /**
     * テスト全体に対するコメント
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'total_feedback'?: string;
    /**
     * ungraded:テスト完了（未採点）、pass:合格、rejection:不合格
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'total_result': string;
    /**
     * 5段階評価1~5
     * @type {number}
     * @memberof GetExamResultsResponse
     */
    'total_score'?: number;
    /**
     * ユーザー＆テストID
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'user_exam_id': string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof GetExamResultsResponse
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface GetLearningUnitByCourseIdsRequest
 */
export interface GetLearningUnitByCourseIdsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetLearningUnitByCourseIdsRequest
     */
    'courseIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetMessagesRequest
 */
export interface GetMessagesRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMessagesRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetMessagesRequest
     */
    'startKey'?: object;
}
/**
 * 
 * @export
 * @interface GetMessagesResponse
 */
export interface GetMessagesResponse {
    /**
     * 
     * @type {Array<MessageEntity>}
     * @memberof GetMessagesResponse
     */
    'items': Array<MessageEntity>;
    /**
     * 
     * @type {object}
     * @memberof GetMessagesResponse
     */
    'lastEvaluatedKey'?: object;
}
/**
 * 
 * @export
 * @interface GetUnitProgressRequest
 */
export interface GetUnitProgressRequest {
    /**
     * 
     * @type {string}
     * @memberof GetUnitProgressRequest
     */
    'courseId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUnitProgressRequest
     */
    'unitIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetUnitProgressResponse
 */
export interface GetUnitProgressResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUnitProgressResponse
     */
    'completedContents': number;
    /**
     * 
     * @type {string}
     * @memberof GetUnitProgressResponse
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUnitProgressResponse
     */
    'unitId': string;
}
/**
 * 
 * @export
 * @interface GetUserTagsByUserIdsRequest
 */
export interface GetUserTagsByUserIdsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserTagsByUserIdsRequest
     */
    'userIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetWatchTimesForHalfYearResponse
 */
export interface GetWatchTimesForHalfYearResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWatchTimesForHalfYearResponse
     */
    'dateValues': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetWatchTimesForHalfYearResponse
     */
    'watchTimeValues': Array<number>;
}
/**
 * 
 * @export
 * @interface LearningUnitEntity
 */
export interface LearningUnitEntity {
    /**
     * company Id
     * @type {string}
     * @memberof LearningUnitEntity
     */
    'company_id': string;
    /**
     * 紐づくcontent数
     * @type {number}
     * @memberof LearningUnitEntity
     */
    'content_count': number;
    /**
     * course id
     * @type {string}
     * @memberof LearningUnitEntity
     */
    'course_id': string;
    /**
     * learning unit id
     * @type {string}
     * @memberof LearningUnitEntity
     */
    'learning_unit_id': string;
    /**
     * タイトル
     * @type {string}
     * @memberof LearningUnitEntity
     */
    'title': string;
    /**
     * 単元順
     * @type {number}
     * @memberof LearningUnitEntity
     */
    'unit_order': number;
}
/**
 * 
 * @export
 * @interface MessageEntity
 */
export interface MessageEntity {
    /**
     * 作成日時
     * @type {string}
     * @memberof MessageEntity
     */
    'created_at': string;
    /**
     * メッセージ文章
     * @type {string}
     * @memberof MessageEntity
     */
    'message_text': string;
    /**
     * メッセージ種類
     * @type {string}
     * @memberof MessageEntity
     */
    'message_type': string;
    /**
     * exam_result_idのリンクとなるUUID
     * @type {string}
     * @memberof MessageEntity
     */
    'target_id': string;
    /**
     * user id
     * @type {string}
     * @memberof MessageEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface MovieEntity
 */
export interface MovieEntity {
    /**
     * 会社ID
     * @type {string}
     * @memberof MovieEntity
     */
    'company_id': string;
    /**
     * コンテンツID
     * @type {string}
     * @memberof MovieEntity
     */
    'content_id': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof MovieEntity
     */
    'created_at': string;
    /**
     * ファイルネーム
     * @type {string}
     * @memberof MovieEntity
     */
    'file_name': string;
    /**
     * 削除フラグ
     * @type {string}
     * @memberof MovieEntity
     */
    'is_deleted': string;
    /**
     * Movie id
     * @type {string}
     * @memberof MovieEntity
     */
    'movie_id': string;
    /**
     * 動画時間
     * @type {string}
     * @memberof MovieEntity
     */
    'movie_time'?: string;
}
/**
 * 
 * @export
 * @interface ProblemAnswer
 */
export interface ProblemAnswer {
    /**
     * 
     * @type {number}
     * @memberof ProblemAnswer
     */
    'exam_problem_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProblemAnswer
     */
    'free_text'?: string;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof ProblemAnswer
     */
    'selection_number'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface ProgressByCourseEntity
 */
export interface ProgressByCourseEntity {
    /**
     * 組織ID
     * @type {string}
     * @memberof ProgressByCourseEntity
     */
    'company_id': string;
    /**
     * 終了したコンテンツ数
     * @type {number}
     * @memberof ProgressByCourseEntity
     */
    'completed_content_count': number;
    /**
     * コースid
     * @type {string}
     * @memberof ProgressByCourseEntity
     */
    'course_id': string;
    /**
     * ユーザid
     * @type {string}
     * @memberof ProgressByCourseEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface ProgressByUnitEntity
 */
export interface ProgressByUnitEntity {
    /**
     * 組織ID
     * @type {string}
     * @memberof ProgressByUnitEntity
     */
    'company_id': string;
    /**
     * 終了したコンテンツ数
     * @type {number}
     * @memberof ProgressByUnitEntity
     */
    'completed_content_count': number;
    /**
     * コースid
     * @type {string}
     * @memberof ProgressByUnitEntity
     */
    'unit_id': string;
    /**
     * ユーザid
     * @type {string}
     * @memberof ProgressByUnitEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface SaveWatchTimeRequest
 */
export interface SaveWatchTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof SaveWatchTimeRequest
     */
    'watchSeconds': number;
}
/**
 * 
 * @export
 * @interface ScoreAndFeedback
 */
export interface ScoreAndFeedback {
    /**
     * 
     * @type {string}
     * @memberof ScoreAndFeedback
     */
    'examProblemId': string;
    /**
     * 
     * @type {string}
     * @memberof ScoreAndFeedback
     */
    'feedbackText'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScoreAndFeedback
     */
    'score': number;
}
/**
 * 
 * @export
 * @interface ScoreAndFeedbackRequest
 */
export interface ScoreAndFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof ScoreAndFeedbackRequest
     */
    'examResultId': string;
    /**
     * 
     * @type {Array<ScoreAndFeedback>}
     * @memberof ScoreAndFeedbackRequest
     */
    'scoreAndFeedback': Array<ScoreAndFeedback>;
}
/**
 * 
 * @export
 * @interface SortContentPageRequest
 */
export interface SortContentPageRequest {
    /**
     * 
     * @type {Array<SortContentRequest>}
     * @memberof SortContentPageRequest
     */
    'sortContents': Array<SortContentRequest>;
}
/**
 * 
 * @export
 * @interface SortContentRequest
 */
export interface SortContentRequest {
    /**
     * 
     * @type {string}
     * @memberof SortContentRequest
     */
    'contentId': string;
    /**
     * 
     * @type {number}
     * @memberof SortContentRequest
     */
    'pageNo': number;
}
/**
 * 
 * @export
 * @interface SortLearningUnitObject
 */
export interface SortLearningUnitObject {
    /**
     * 
     * @type {string}
     * @memberof SortLearningUnitObject
     */
    'learningUnitId': string;
    /**
     * 
     * @type {number}
     * @memberof SortLearningUnitObject
     */
    'unitOrder': number;
}
/**
 * 
 * @export
 * @interface SortLearningUnitRequest
 */
export interface SortLearningUnitRequest {
    /**
     * 
     * @type {Array<SortLearningUnitObject>}
     * @memberof SortLearningUnitRequest
     */
    'sortObject': Array<SortLearningUnitObject>;
}
/**
 * 
 * @export
 * @interface StudentAnswerEntity
 */
export interface StudentAnswerEntity {
    /**
     * 作成日時
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'created_at': string;
    /**
     * 試験IDと問題IDの組み合わせ
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'exam_problem_path': string;
    /**
     * exam result id
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'exam_result_id': string;
    /**
     * フィードバックコメント
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'feedback_text'?: string;
    /**
     * フィードバックしたユーザー名
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'feedback_user'?: string;
    /**
     * 自由解答
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'free_text'?: string;
    /**
     * 5段階評価
     * @type {number}
     * @memberof StudentAnswerEntity
     */
    'score'?: number;
    /**
     * 選択肢解答
     * @type {Array<boolean>}
     * @memberof StudentAnswerEntity
     */
    'selection_number'?: Array<boolean>;
    /**
     * ユーザーID
     * @type {string}
     * @memberof StudentAnswerEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface StudentAnswerResponse
 */
export interface StudentAnswerResponse {
    /**
     * 作成日時
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'created_at': string;
    /**
     * 試験IDと問題IDの組み合わせ
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'exam_problem_path': string;
    /**
     * exam result id
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'exam_result_id': string;
    /**
     * フィードバックコメント
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'feedback_text'?: string;
    /**
     * フィードバックしたユーザー名
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'feedback_user'?: string;
    /**
     * 自由解答
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'free_text'?: string;
    /**
     * 5段階評価
     * @type {number}
     * @memberof StudentAnswerResponse
     */
    'score'?: number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof StudentAnswerResponse
     */
    'selection_answer'?: Array<boolean>;
    /**
     * 選択肢解答
     * @type {Array<boolean>}
     * @memberof StudentAnswerResponse
     */
    'selection_number'?: Array<boolean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StudentAnswerResponse
     */
    'selections'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'title'?: string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof StudentAnswerResponse
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface TagEntity
 */
export interface TagEntity {
    /**
     * 組織ID
     * @type {string}
     * @memberof TagEntity
     */
    'company_id': string;
    /**
     * tag id
     * @type {string}
     * @memberof TagEntity
     */
    'tag_id': string;
    /**
     * タグ名
     * @type {string}
     * @memberof TagEntity
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface TotalScoreRequest
 */
export interface TotalScoreRequest {
    /**
     * 
     * @type {string}
     * @memberof TotalScoreRequest
     */
    'examResultId': string;
    /**
     * 
     * @type {string}
     * @memberof TotalScoreRequest
     */
    'totalFeedback': string;
    /**
     * 
     * @type {string}
     * @memberof TotalScoreRequest
     */
    'totalResult': string;
    /**
     * 
     * @type {number}
     * @memberof TotalScoreRequest
     */
    'totalScore': number;
}
/**
 * 
 * @export
 * @interface UpdateCourseAssignedRequest
 */
export interface UpdateCourseAssignedRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseAssignedRequest
     */
    'course_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCourseAssignedRequest
     */
    'is_assigned': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseAssignedRequest
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface UpdateCourseRequest
 */
export interface UpdateCourseRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseRequest
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseRequest
     */
    'requiredTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseRequest
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface UpdateCourseRequiredRequest
 */
export interface UpdateCourseRequiredRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseRequiredRequest
     */
    'course_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCourseRequiredRequest
     */
    'is_required': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseRequiredRequest
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface UpdateExamOpeningRequest
 */
export interface UpdateExamOpeningRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExamOpeningRequest
     */
    'examId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExamOpeningRequest
     */
    'opening': boolean;
}
/**
 * 
 * @export
 * @interface UpdateExamRequest
 */
export interface UpdateExamRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExamRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExamRequest
     */
    'examId': string;
}
/**
 * 
 * @export
 * @interface UpdateLearningUnitRequest
 */
export interface UpdateLearningUnitRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLearningUnitRequest
     */
    'learningUnitId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLearningUnitRequest
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface UpdateTagRequestDto
 */
export interface UpdateTagRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequestDto
     */
    'tagId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequestDto
     */
    'tagTitle': string;
}
/**
 * 
 * @export
 * @interface UpdateTextContentRequest
 */
export interface UpdateTextContentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTextContentRequest
     */
    'contentId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTextContentRequest
     */
    'contentJson': string;
}
/**
 * 
 * @export
 * @interface UpdateTitleRequest
 */
export interface UpdateTitleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTitleRequest
     */
    'contentId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTitleRequest
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface UpdateVTTRequest
 */
export interface UpdateVTTRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateVTTRequest
     */
    'movieId': string;
}
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof UserCreateRequest
     */
    'role': UserRole;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateRequest
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserEntityForResponse
 */
export interface UserEntityForResponse {
    /**
     * 
     * @type {string}
     * @memberof UserEntityForResponse
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntityForResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntityForResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntityForResponse
     */
    'role': UserEntityForResponseRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserEntityForResponse
     */
    'user_id': string;
}

export const UserEntityForResponseRoleEnum = {
    Student: 'student',
    Admin: 'admin'
} as const;

export type UserEntityForResponseRoleEnum = typeof UserEntityForResponseRoleEnum[keyof typeof UserEntityForResponseRoleEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    Student: 'student',
    Admin: 'admin'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface UserTagEntity
 */
export interface UserTagEntity {
    /**
     * tag id
     * @type {string}
     * @memberof UserTagEntity
     */
    'tag_id': string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof UserTagEntity
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof UserUpdateRequest
     */
    'role': UserRole;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateRequest
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'userId': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {AccountPasswordResetRequest} accountPasswordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerResetPassword: async (accountPasswordResetRequest: AccountPasswordResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountPasswordResetRequest' is not null or undefined
            assertParamExists('accountControllerResetPassword', 'accountPasswordResetRequest', accountPasswordResetRequest)
            const localVarPath = `/account/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountPasswordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {AccountPasswordResetInitiateRequest} accountPasswordResetInitiateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerSendPasswordResetLink: async (accountPasswordResetInitiateRequest: AccountPasswordResetInitiateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountPasswordResetInitiateRequest' is not null or undefined
            assertParamExists('accountControllerSendPasswordResetLink', 'accountPasswordResetInitiateRequest', accountPasswordResetInitiateRequest)
            const localVarPath = `/account/send_password_reset_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountPasswordResetInitiateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {AuthAccessTokenRequest} authAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAccessToken: async (authAccessTokenRequest: AuthAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authAccessTokenRequest' is not null or undefined
            assertParamExists('authControllerAccessToken', 'authAccessTokenRequest', authAccessTokenRequest)
            const localVarPath = `/auth/access_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAccessTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * emailとpasswordでログイン。passwordは事前にハッシュをとったものを受け付けるが、これは不用意にログなどに生パスワードが出てしまうのを防ぐため。サーバー上で再度ハッシュをとっている。
         * @summary 
         * @param {AuthRefreshTokenRequest} authRefreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken: async (authRefreshTokenRequest: AuthRefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRefreshTokenRequest' is not null or undefined
            assertParamExists('authControllerRefreshToken', 'authRefreshTokenRequest', authRefreshTokenRequest)
            const localVarPath = `/auth/refresh_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRefreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateContentRequest} createContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerCreateContent: async (createContentRequest: CreateContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContentRequest' is not null or undefined
            assertParamExists('contentControllerCreateContent', 'createContentRequest', createContentRequest)
            const localVarPath = `/content/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerDeleteContent: async (contentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('contentControllerDeleteContent', 'contentId', contentId)
            const localVarPath = `/content/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerGetContentById: async (contentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('contentControllerGetContentById', 'contentId', contentId)
            const localVarPath = `/content/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerGetContentsByUnitId: async (unitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('contentControllerGetContentsByUnitId', 'unitId', unitId)
            const localVarPath = `/content/{unit_id}/unit`
                .replace(`{${"unit_id"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerIsAccessibleToContent: async (contentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('contentControllerIsAccessibleToContent', 'contentId', contentId)
            const localVarPath = `/content/{contentId}/accessible`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerIsAccessibleToTest: async (unitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('contentControllerIsAccessibleToTest', 'unitId', unitId)
            const localVarPath = `/content/unit/{unitId}/accessible`
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SortContentPageRequest} sortContentPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerSortContentPage: async (sortContentPageRequest: SortContentPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortContentPageRequest' is not null or undefined
            assertParamExists('contentControllerSortContentPage', 'sortContentPageRequest', sortContentPageRequest)
            const localVarPath = `/content/sortContentPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortContentPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateTextContentRequest} updateTextContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerUpdateTextContent: async (updateTextContentRequest: UpdateTextContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTextContentRequest' is not null or undefined
            assertParamExists('contentControllerUpdateTextContent', 'updateTextContentRequest', updateTextContentRequest)
            const localVarPath = `/content/updateText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTextContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateTitleRequest} updateTitleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerUpdateTitle: async (updateTitleRequest: UpdateTitleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTitleRequest' is not null or undefined
            assertParamExists('contentControllerUpdateTitle', 'updateTitleRequest', updateTitleRequest)
            const localVarPath = `/content/updateTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTitleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseRequest} createCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerCreateCourse: async (createCourseRequest: CreateCourseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCourseRequest' is not null or undefined
            assertParamExists('courseControllerCreateCourse', 'createCourseRequest', createCourseRequest)
            const localVarPath = `/course/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {DeleteCourseRequest} deleteCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerDeleteCourse: async (deleteCourseRequest: DeleteCourseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCourseRequest' is not null or undefined
            assertParamExists('courseControllerDeleteCourse', 'deleteCourseRequest', deleteCourseRequest)
            const localVarPath = `/course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetCourseById: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('courseControllerGetCourseById', 'courseId', courseId)
            const localVarPath = `/course/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetCoursesByCompanyId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/course/by-company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetCoursesByMyCompanyId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/course/my_company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseRequest} updateCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerUpdateCourse: async (updateCourseRequest: UpdateCourseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCourseRequest' is not null or undefined
            assertParamExists('courseControllerUpdateCourse', 'updateCourseRequest', updateCourseRequest)
            const localVarPath = `/course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseMapRequest} createCourseMapRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerCreateCourseMap: async (createCourseMapRequest: CreateCourseMapRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCourseMapRequest' is not null or undefined
            assertParamExists('courseMapControllerCreateCourseMap', 'createCourseMapRequest', createCourseMapRequest)
            const localVarPath = `/course-map/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCourseMapRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetCourseAssignRequest} getCourseAssignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseAssign: async (getCourseAssignRequest: GetCourseAssignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCourseAssignRequest' is not null or undefined
            assertParamExists('courseMapControllerGetCourseAssign', 'getCourseAssignRequest', getCourseAssignRequest)
            const localVarPath = `/course-map/assign/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCourseAssignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMap: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('courseMapControllerGetCourseMap', 'courseId', courseId)
            const localVarPath = `/course-map/{course_id}/get`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMapByCompanyId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/course-map/by-company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMapByCourseId: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('courseMapControllerGetCourseMapByCourseId', 'courseId', courseId)
            const localVarPath = `/course-map/by-course/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMapByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('courseMapControllerGetCourseMapByUserId', 'userId', userId)
            const localVarPath = `/course-map/{userId}/by-user`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseAssignedRequest} updateCourseAssignedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerUpsertCourseAssigned: async (updateCourseAssignedRequest: UpdateCourseAssignedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCourseAssignedRequest' is not null or undefined
            assertParamExists('courseMapControllerUpsertCourseAssigned', 'updateCourseAssignedRequest', updateCourseAssignedRequest)
            const localVarPath = `/course-map/assign/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCourseAssignedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseRequiredRequest} updateCourseRequiredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerUpsertCourseRequired: async (updateCourseRequiredRequest: UpdateCourseRequiredRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCourseRequiredRequest' is not null or undefined
            assertParamExists('courseMapControllerUpsertCourseRequired', 'updateCourseRequiredRequest', updateCourseRequiredRequest)
            const localVarPath = `/course-map/require/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCourseRequiredRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateExamRequest} createExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerCreateEmptyExam: async (createExamRequest: CreateExamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExamRequest' is not null or undefined
            assertParamExists('examControllerCreateEmptyExam', 'createExamRequest', createExamRequest)
            const localVarPath = `/exam/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {EditExamRequest} editExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerEditExamProblem: async (editExamRequest: EditExamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editExamRequest' is not null or undefined
            assertParamExists('examControllerEditExamProblem', 'editExamRequest', editExamRequest)
            const localVarPath = `/exam/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editExamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} examId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerGetExamAndProblems: async (examId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('examControllerGetExamAndProblems', 'examId', examId)
            const localVarPath = `/exam/{examId}/get`
                .replace(`{${"examId"}}`, encodeURIComponent(String(examId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerGetExamByUnitId: async (unitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('examControllerGetExamByUnitId', 'unitId', unitId)
            const localVarPath = `/exam/{unitId}/get-by-unit`
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateExamRequest} updateExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerUpdateExam: async (updateExamRequest: UpdateExamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateExamRequest' is not null or undefined
            assertParamExists('examControllerUpdateExam', 'updateExamRequest', updateExamRequest)
            const localVarPath = `/exam/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateExamOpeningRequest} updateExamOpeningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerUpdateOpening: async (updateExamOpeningRequest: UpdateExamOpeningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateExamOpeningRequest' is not null or undefined
            assertParamExists('examControllerUpdateOpening', 'updateExamOpeningRequest', updateExamOpeningRequest)
            const localVarPath = `/exam/opening`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExamOpeningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} examId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examProblemControllerGetExamProblems: async (examId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('examProblemControllerGetExamProblems', 'examId', examId)
            const localVarPath = `/exam-problem/{examId}`
                .replace(`{${"examId"}}`, encodeURIComponent(String(examId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateCommentRequest} createCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultCommentControllerCreateComment: async (createCommentRequest: CreateCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommentRequest' is not null or undefined
            assertParamExists('examResultCommentControllerCreateComment', 'createCommentRequest', createCommentRequest)
            const localVarPath = `/exam-result-comment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultCommentControllerGetComments: async (examResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examResultId' is not null or undefined
            assertParamExists('examResultCommentControllerGetComments', 'examResultId', examResultId)
            const localVarPath = `/exam-result-comment/{exam_result_id}`
                .replace(`{${"exam_result_id"}}`, encodeURIComponent(String(examResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateExamResultRequest} createExamResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerCreateExamResult: async (createExamResultRequest: CreateExamResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExamResultRequest' is not null or undefined
            assertParamExists('examResultControllerCreateExamResult', 'createExamResultRequest', createExamResultRequest)
            const localVarPath = `/exam-result/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExamResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResults: async (examResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examResultId' is not null or undefined
            assertParamExists('examResultControllerGetExamResults', 'examResultId', examResultId)
            const localVarPath = `/exam-result/{examResultId}/with-answer`
                .replace(`{${"examResultId"}}`, encodeURIComponent(String(examResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} learningUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResultsByUnitId: async (learningUnitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'learningUnitId' is not null or undefined
            assertParamExists('examResultControllerGetExamResultsByUnitId', 'learningUnitId', learningUnitId)
            const localVarPath = `/exam-result/{learningUnitId}/get-by-unit-id`
                .replace(`{${"learningUnitId"}}`, encodeURIComponent(String(learningUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userExamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResultsByUserExamId: async (userExamId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userExamId' is not null or undefined
            assertParamExists('examResultControllerGetExamResultsByUserExamId', 'userExamId', userExamId)
            const localVarPath = `/exam-result/{userExamId}/get-by-user-exam`
                .replace(`{${"userExamId"}}`, encodeURIComponent(String(userExamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResultsByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('examResultControllerGetExamResultsByUserId', 'userId', userId)
            const localVarPath = `/exam-result/{userId}/by-user`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetMyExamResults: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/exam-result/my-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ScoreAndFeedbackRequest} scoreAndFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerUpdateExamResultScoreAndFeedback: async (scoreAndFeedbackRequest: ScoreAndFeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scoreAndFeedbackRequest' is not null or undefined
            assertParamExists('examResultControllerUpdateExamResultScoreAndFeedback', 'scoreAndFeedbackRequest', scoreAndFeedbackRequest)
            const localVarPath = `/exam-result/score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scoreAndFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {TotalScoreRequest} totalScoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerUpdateExamResultTotalScoreAndFeedback: async (totalScoreRequest: TotalScoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'totalScoreRequest' is not null or undefined
            assertParamExists('examResultControllerUpdateExamResultTotalScoreAndFeedback', 'totalScoreRequest', totalScoreRequest)
            const localVarPath = `/exam-result/total-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(totalScoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageControllerIssueImageDownloadURL: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('imageControllerIssueImageDownloadURL', 'imageId', imageId)
            const localVarPath = `/image/{imageId}/download`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateImageRequest} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageControllerIssueImageUploadURL: async (createImageRequest: CreateImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createImageRequest' is not null or undefined
            assertParamExists('imageControllerIssueImageUploadURL', 'createImageRequest', createImageRequest)
            const localVarPath = `/image/upload-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateLearningUnitRequest} createLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerCreateLearningUnit: async (createLearningUnitRequest: CreateLearningUnitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLearningUnitRequest' is not null or undefined
            assertParamExists('learningUnitControllerCreateLearningUnit', 'createLearningUnitRequest', createLearningUnitRequest)
            const localVarPath = `/learning-unit/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLearningUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {DeleteLearningUnitRequest} deleteLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerDeleteLearningUnit: async (deleteLearningUnitRequest: DeleteLearningUnitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteLearningUnitRequest' is not null or undefined
            assertParamExists('learningUnitControllerDeleteLearningUnit', 'deleteLearningUnitRequest', deleteLearningUnitRequest)
            const localVarPath = `/learning-unit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteLearningUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerGetLearningUnitById: async (unitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('learningUnitControllerGetLearningUnitById', 'unitId', unitId)
            const localVarPath = `/learning-unit/{unitId}`
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerGetLearningUnitsByCourseId: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('learningUnitControllerGetLearningUnitsByCourseId', 'courseId', courseId)
            const localVarPath = `/learning-unit/course/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetLearningUnitByCourseIdsRequest} getLearningUnitByCourseIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerGetLearningUnitsByCourseIds: async (getLearningUnitByCourseIdsRequest: GetLearningUnitByCourseIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getLearningUnitByCourseIdsRequest' is not null or undefined
            assertParamExists('learningUnitControllerGetLearningUnitsByCourseIds', 'getLearningUnitByCourseIdsRequest', getLearningUnitByCourseIdsRequest)
            const localVarPath = `/learning-unit/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getLearningUnitByCourseIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SortLearningUnitRequest} sortLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerSortLearningUnit: async (sortLearningUnitRequest: SortLearningUnitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortLearningUnitRequest' is not null or undefined
            assertParamExists('learningUnitControllerSortLearningUnit', 'sortLearningUnitRequest', sortLearningUnitRequest)
            const localVarPath = `/learning-unit/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortLearningUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateLearningUnitRequest} updateLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerUpdateLearningUnit: async (updateLearningUnitRequest: UpdateLearningUnitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLearningUnitRequest' is not null or undefined
            assertParamExists('learningUnitControllerUpdateLearningUnit', 'updateLearningUnitRequest', updateLearningUnitRequest)
            const localVarPath = `/learning-unit/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLearningUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetMessagesRequest} getMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerGetMessages: async (getMessagesRequest: GetMessagesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getMessagesRequest' is not null or undefined
            assertParamExists('messageControllerGetMessages', 'getMessagesRequest', getMessagesRequest)
            const localVarPath = `/message/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMessagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerCheckMovieExistence: async (movieId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'movieId' is not null or undefined
            assertParamExists('movieControllerCheckMovieExistence', 'movieId', movieId)
            const localVarPath = `/movie/{movieId}/check`
                .replace(`{${"movieId"}}`, encodeURIComponent(String(movieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerDeleteMovie: async (movieId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'movieId' is not null or undefined
            assertParamExists('movieControllerDeleteMovie', 'movieId', movieId)
            const localVarPath = `/movie/{movieId}`
                .replace(`{${"movieId"}}`, encodeURIComponent(String(movieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerGetMovieByContentId: async (contentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('movieControllerGetMovieByContentId', 'contentId', contentId)
            const localVarPath = `/movie/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerGetVTTFileURL: async (movieId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'movieId' is not null or undefined
            assertParamExists('movieControllerGetVTTFileURL', 'movieId', movieId)
            const localVarPath = `/movie/{movieId}/vtt`
                .replace(`{${"movieId"}}`, encodeURIComponent(String(movieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateMovieRequest} createMovieRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerIssueMovieUploadURL: async (createMovieRequest: CreateMovieRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMovieRequest' is not null or undefined
            assertParamExists('movieControllerIssueMovieUploadURL', 'createMovieRequest', createMovieRequest)
            const localVarPath = `/movie/upload-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMovieRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateVTTRequest} updateVTTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerIssueVTTUploadURL: async (updateVTTRequest: UpdateVTTRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateVTTRequest' is not null or undefined
            assertParamExists('movieControllerIssueVTTUploadURL', 'updateVTTRequest', updateVTTRequest)
            const localVarPath = `/movie/vtt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVTTRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moviePlayHistoryControllerCalcTotalWatchTime: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movie-play-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moviePlayHistoryControllerGetWatchTimesForHalfYear: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movie-play-history/get-half-year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SaveWatchTimeRequest} saveWatchTimeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moviePlayHistoryControllerSaveWatchTime: async (saveWatchTimeRequest: SaveWatchTimeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveWatchTimeRequest' is not null or undefined
            assertParamExists('moviePlayHistoryControllerSaveWatchTime', 'saveWatchTimeRequest', saveWatchTimeRequest)
            const localVarPath = `/movie-play-history/save-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWatchTimeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseProgressRequest} createCourseProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerCreateProgress: async (createCourseProgressRequest: CreateCourseProgressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCourseProgressRequest' is not null or undefined
            assertParamExists('progressControllerCreateProgress', 'createCourseProgressRequest', createCourseProgressRequest)
            const localVarPath = `/progress/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCourseProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerCourseByCompanyId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/progress/per-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetCompletedCountByUserId} getCompletedCountByUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerCourseByUserId: async (getCompletedCountByUserId: GetCompletedCountByUserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCompletedCountByUserId' is not null or undefined
            assertParamExists('progressControllerGetCompletedCountPerCourseByUserId', 'getCompletedCountByUserId', getCompletedCountByUserId)
            const localVarPath = `/progress/per-course/by-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCompletedCountByUserId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CountPerUnitByUnitIdsRequest} countPerUnitByUnitIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerUnitByUnitIds: async (countPerUnitByUnitIdsRequest: CountPerUnitByUnitIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countPerUnitByUnitIdsRequest' is not null or undefined
            assertParamExists('progressControllerGetCompletedCountPerUnitByUnitIds', 'countPerUnitByUnitIdsRequest', countPerUnitByUnitIdsRequest)
            const localVarPath = `/progress/units/batch-get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countPerUnitByUnitIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerUnitByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('progressControllerGetCompletedCountPerUnitByUserId', 'userId', userId)
            const localVarPath = `/progress/unit/by-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCourseCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetCourseProgressRequest} getCourseProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetProgressByCourses: async (getCourseProgressRequest: GetCourseProgressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCourseProgressRequest' is not null or undefined
            assertParamExists('progressControllerGetProgressByCourses', 'getCourseProgressRequest', getCourseProgressRequest)
            const localVarPath = `/progress/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCourseProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetUnitProgressRequest} getUnitProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetProgressByUnits: async (getUnitProgressRequest: GetUnitProgressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUnitProgressRequest' is not null or undefined
            assertParamExists('progressControllerGetProgressByUnits', 'getUnitProgressRequest', getUnitProgressRequest)
            const localVarPath = `/progress/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUnitProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentAnswerControllerGetStudentAnswersWithProblems: async (examResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examResultId' is not null or undefined
            assertParamExists('studentAnswerControllerGetStudentAnswersWithProblems', 'examResultId', examResultId)
            const localVarPath = `/student-answer/{examResultId}`
                .replace(`{${"examResultId"}}`, encodeURIComponent(String(examResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateTagIdRequestDto} createTagIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerCreateTag: async (createTagIdRequestDto: CreateTagIdRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagIdRequestDto' is not null or undefined
            assertParamExists('tagControllerCreateTag', 'createTagIdRequestDto', createTagIdRequestDto)
            const localVarPath = `/tag/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagIdRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {DeleteTagIdRequestDto} deleteTagIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerDeleteTag: async (deleteTagIdRequestDto: DeleteTagIdRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTagIdRequestDto' is not null or undefined
            assertParamExists('tagControllerDeleteTag', 'deleteTagIdRequestDto', deleteTagIdRequestDto)
            const localVarPath = `/tag/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTagIdRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerGetAllTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerGetTagItem: async (tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('tagControllerGetTagItem', 'tagId', tagId)
            const localVarPath = `/tag/{tag_id}`
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateTagRequestDto} updateTagRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerUpdateTag: async (updateTagRequestDto: UpdateTagRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTagRequestDto' is not null or undefined
            assertParamExists('tagControllerUpdateTag', 'updateTagRequestDto', updateTagRequestDto)
            const localVarPath = `/tag/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTagRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate: async (userCreateRequest: UserCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequest' is not null or undefined
            assertParamExists('userControllerCreate', 'userCreateRequest', userCreateRequest)
            const localVarPath = `/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteUsers: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerDeleteUsers', 'userId', userId)
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserName: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetUserName', 'userId', userId)
            const localVarPath = `/user/{userId}/name`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser: async (userUpdateRequest: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdateRequest' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'userUpdateRequest', userUpdateRequest)
            const localVarPath = `/user/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTagControllerGetTagIDsByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userTagControllerGetTagIDsByUserId', 'userId', userId)
            const localVarPath = `/user-tag/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetUserTagsByUserIdsRequest} getUserTagsByUserIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTagControllerGetTagIDsByUserIds: async (getUserTagsByUserIdsRequest: GetUserTagsByUserIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUserTagsByUserIdsRequest' is not null or undefined
            assertParamExists('userTagControllerGetTagIDsByUserIds', 'getUserTagsByUserIdsRequest', getUserTagsByUserIdsRequest)
            const localVarPath = `/user-tag/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUserTagsByUserIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {AccountPasswordResetRequest} accountPasswordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerResetPassword(accountPasswordResetRequest: AccountPasswordResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerResetPassword(accountPasswordResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {AccountPasswordResetInitiateRequest} accountPasswordResetInitiateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerSendPasswordResetLink(accountPasswordResetInitiateRequest: AccountPasswordResetInitiateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerSendPasswordResetLink(accountPasswordResetInitiateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {AuthAccessTokenRequest} authAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAccessToken(authAccessTokenRequest: AuthAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAccessToken(authAccessTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * emailとpasswordでログイン。passwordは事前にハッシュをとったものを受け付けるが、これは不用意にログなどに生パスワードが出てしまうのを防ぐため。サーバー上で再度ハッシュをとっている。
         * @summary 
         * @param {AuthRefreshTokenRequest} authRefreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshToken(authRefreshTokenRequest: AuthRefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthRefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshToken(authRefreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateContentRequest} createContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerCreateContent(createContentRequest: CreateContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerCreateContent(createContentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerDeleteContent(contentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerDeleteContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetContentById(contentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerGetContentById(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetContentsByUnitId(unitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerGetContentsByUnitId(unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerIsAccessibleToContent(contentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerIsAccessibleToContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerIsAccessibleToTest(unitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerIsAccessibleToTest(unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SortContentPageRequest} sortContentPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerSortContentPage(sortContentPageRequest: SortContentPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerSortContentPage(sortContentPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateTextContentRequest} updateTextContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerUpdateTextContent(updateTextContentRequest: UpdateTextContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerUpdateTextContent(updateTextContentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateTitleRequest} updateTitleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerUpdateTitle(updateTitleRequest: UpdateTitleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentControllerUpdateTitle(updateTitleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseRequest} createCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerCreateCourse(createCourseRequest: CreateCourseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerCreateCourse(createCourseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {DeleteCourseRequest} deleteCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerDeleteCourse(deleteCourseRequest: DeleteCourseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerDeleteCourse(deleteCourseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerGetCourseById(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerGetCourseById(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerGetCoursesByCompanyId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerGetCoursesByCompanyId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerGetCoursesByMyCompanyId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerGetCoursesByMyCompanyId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseRequest} updateCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerUpdateCourse(updateCourseRequest: UpdateCourseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerUpdateCourse(updateCourseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseMapRequest} createCourseMapRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerCreateCourseMap(createCourseMapRequest: CreateCourseMapRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerCreateCourseMap(createCourseMapRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetCourseAssignRequest} getCourseAssignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerGetCourseAssign(getCourseAssignRequest: GetCourseAssignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseMapEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerGetCourseAssign(getCourseAssignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerGetCourseMap(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseMapResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerGetCourseMap(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerGetCourseMapByCompanyId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseMapEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerGetCourseMapByCompanyId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerGetCourseMapByCourseId(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseMapEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerGetCourseMapByCourseId(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerGetCourseMapByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCourseMapResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerGetCourseMapByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseAssignedRequest} updateCourseAssignedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerUpsertCourseAssigned(updateCourseAssignedRequest: UpdateCourseAssignedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerUpsertCourseAssigned(updateCourseAssignedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseRequiredRequest} updateCourseRequiredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseMapControllerUpsertCourseRequired(updateCourseRequiredRequest: UpdateCourseRequiredRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseMapControllerUpsertCourseRequired(updateCourseRequiredRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateExamRequest} createExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examControllerCreateEmptyExam(createExamRequest: CreateExamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examControllerCreateEmptyExam(createExamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {EditExamRequest} editExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examControllerEditExamProblem(editExamRequest: EditExamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examControllerEditExamProblem(editExamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} examId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examControllerGetExamAndProblems(examId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExamProblemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examControllerGetExamAndProblems(examId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examControllerGetExamByUnitId(unitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examControllerGetExamByUnitId(unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateExamRequest} updateExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examControllerUpdateExam(updateExamRequest: UpdateExamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examControllerUpdateExam(updateExamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateExamOpeningRequest} updateExamOpeningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examControllerUpdateOpening(updateExamOpeningRequest: UpdateExamOpeningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examControllerUpdateOpening(updateExamOpeningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} examId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examProblemControllerGetExamProblems(examId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamProblemEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examProblemControllerGetExamProblems(examId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateCommentRequest} createCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultCommentControllerCreateComment(createCommentRequest: CreateCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultCommentControllerCreateComment(createCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultCommentControllerGetComments(examResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamResultCommentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultCommentControllerGetComments(examResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateExamResultRequest} createExamResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerCreateExamResult(createExamResultRequest: CreateExamResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerCreateExamResult(createExamResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerGetExamResults(examResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExamResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerGetExamResults(examResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} learningUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerGetExamResultsByUnitId(learningUnitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamResultEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerGetExamResultsByUnitId(learningUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userExamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerGetExamResultsByUserExamId(userExamId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamResultEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerGetExamResultsByUserExamId(userExamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerGetExamResultsByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamResultEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerGetExamResultsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerGetMyExamResults(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamResultEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerGetMyExamResults(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ScoreAndFeedbackRequest} scoreAndFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerUpdateExamResultScoreAndFeedback(scoreAndFeedbackRequest: ScoreAndFeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerUpdateExamResultScoreAndFeedback(scoreAndFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {TotalScoreRequest} totalScoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examResultControllerUpdateExamResultTotalScoreAndFeedback(totalScoreRequest: TotalScoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examResultControllerUpdateExamResultTotalScoreAndFeedback(totalScoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageControllerIssueImageDownloadURL(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageControllerIssueImageDownloadURL(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateImageRequest} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageControllerIssueImageUploadURL(createImageRequest: CreateImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageControllerIssueImageUploadURL(createImageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateLearningUnitRequest} createLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerCreateLearningUnit(createLearningUnitRequest: CreateLearningUnitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerCreateLearningUnit(createLearningUnitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {DeleteLearningUnitRequest} deleteLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerDeleteLearningUnit(deleteLearningUnitRequest: DeleteLearningUnitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerDeleteLearningUnit(deleteLearningUnitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerGetLearningUnitById(unitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearningUnitEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerGetLearningUnitById(unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerGetLearningUnitsByCourseId(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LearningUnitEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerGetLearningUnitsByCourseId(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetLearningUnitByCourseIdsRequest} getLearningUnitByCourseIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerGetLearningUnitsByCourseIds(getLearningUnitByCourseIdsRequest: GetLearningUnitByCourseIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LearningUnitEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerGetLearningUnitsByCourseIds(getLearningUnitByCourseIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SortLearningUnitRequest} sortLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerSortLearningUnit(sortLearningUnitRequest: SortLearningUnitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerSortLearningUnit(sortLearningUnitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateLearningUnitRequest} updateLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async learningUnitControllerUpdateLearningUnit(updateLearningUnitRequest: UpdateLearningUnitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.learningUnitControllerUpdateLearningUnit(updateLearningUnitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetMessagesRequest} getMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageControllerGetMessages(getMessagesRequest: GetMessagesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageControllerGetMessages(getMessagesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movieControllerCheckMovieExistence(movieId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movieControllerCheckMovieExistence(movieId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movieControllerDeleteMovie(movieId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovieEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movieControllerDeleteMovie(movieId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movieControllerGetMovieByContentId(contentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovieEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movieControllerGetMovieByContentId(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movieControllerGetVTTFileURL(movieId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movieControllerGetVTTFileURL(movieId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateMovieRequest} createMovieRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movieControllerIssueMovieUploadURL(createMovieRequest: CreateMovieRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movieControllerIssueMovieUploadURL(createMovieRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateVTTRequest} updateVTTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movieControllerIssueVTTUploadURL(updateVTTRequest: UpdateVTTRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movieControllerIssueVTTUploadURL(updateVTTRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moviePlayHistoryControllerCalcTotalWatchTime(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moviePlayHistoryControllerCalcTotalWatchTime(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moviePlayHistoryControllerGetWatchTimesForHalfYear(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWatchTimesForHalfYearResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moviePlayHistoryControllerGetWatchTimesForHalfYear(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SaveWatchTimeRequest} saveWatchTimeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moviePlayHistoryControllerSaveWatchTime(saveWatchTimeRequest: SaveWatchTimeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moviePlayHistoryControllerSaveWatchTime(saveWatchTimeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseProgressRequest} createCourseProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerCreateProgress(createCourseProgressRequest: CreateCourseProgressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerCreateProgress(createCourseProgressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetCompletedCountPerCourseByCompanyId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgressByCourseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetCompletedCountPerCourseByCompanyId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetCompletedCountByUserId} getCompletedCountByUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetCompletedCountPerCourseByUserId(getCompletedCountByUserId: GetCompletedCountByUserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgressByCourseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetCompletedCountPerCourseByUserId(getCompletedCountByUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CountPerUnitByUnitIdsRequest} countPerUnitByUnitIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetCompletedCountPerUnitByUnitIds(countPerUnitByUnitIdsRequest: CountPerUnitByUnitIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgressByUnitEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetCompletedCountPerUnitByUnitIds(countPerUnitByUnitIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetCompletedCountPerUnitByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgressByUnitEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetCompletedCountPerUnitByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetCompletedCourseCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetCompletedCourseCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetCourseProgressRequest} getCourseProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetProgressByCourses(getCourseProgressRequest: GetCourseProgressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCourseProgressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetProgressByCourses(getCourseProgressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetUnitProgressRequest} getUnitProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressControllerGetProgressByUnits(getUnitProgressRequest: GetUnitProgressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetUnitProgressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressControllerGetProgressByUnits(getUnitProgressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentAnswerControllerGetStudentAnswersWithProblems(examResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentAnswerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentAnswerControllerGetStudentAnswersWithProblems(examResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateTagIdRequestDto} createTagIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagControllerCreateTag(createTagIdRequestDto: CreateTagIdRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagControllerCreateTag(createTagIdRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {DeleteTagIdRequestDto} deleteTagIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagControllerDeleteTag(deleteTagIdRequestDto: DeleteTagIdRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagControllerDeleteTag(deleteTagIdRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagControllerGetAllTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagControllerGetAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagControllerGetTagItem(tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagControllerGetTagItem(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateTagRequestDto} updateTagRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagControllerUpdateTag(updateTagRequestDto: UpdateTagRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagControllerUpdateTag(updateTagRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreate(userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreate(userCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteUsers(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteUsers(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserName(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserName(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserEntityForResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUser(userUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(userUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTagControllerGetTagIDsByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTagEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userTagControllerGetTagIDsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetUserTagsByUserIdsRequest} getUserTagsByUserIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTagControllerGetTagIDsByUserIds(getUserTagsByUserIdsRequest: GetUserTagsByUserIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTagEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userTagControllerGetTagIDsByUserIds(getUserTagsByUserIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {AccountPasswordResetRequest} accountPasswordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerResetPassword(accountPasswordResetRequest: AccountPasswordResetRequest, options?: any): AxiosPromise<string> {
            return localVarFp.accountControllerResetPassword(accountPasswordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {AccountPasswordResetInitiateRequest} accountPasswordResetInitiateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerSendPasswordResetLink(accountPasswordResetInitiateRequest: AccountPasswordResetInitiateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.accountControllerSendPasswordResetLink(accountPasswordResetInitiateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {AuthAccessTokenRequest} authAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAccessToken(authAccessTokenRequest: AuthAccessTokenRequest, options?: any): AxiosPromise<AuthAccessTokenResponse> {
            return localVarFp.authControllerAccessToken(authAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerMe(options?: any): AxiosPromise<AuthMeResponse> {
            return localVarFp.authControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * emailとpasswordでログイン。passwordは事前にハッシュをとったものを受け付けるが、これは不用意にログなどに生パスワードが出てしまうのを防ぐため。サーバー上で再度ハッシュをとっている。
         * @summary 
         * @param {AuthRefreshTokenRequest} authRefreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(authRefreshTokenRequest: AuthRefreshTokenRequest, options?: any): AxiosPromise<AuthRefreshTokenResponse> {
            return localVarFp.authControllerRefreshToken(authRefreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateContentRequest} createContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerCreateContent(createContentRequest: CreateContentRequest, options?: any): AxiosPromise<string> {
            return localVarFp.contentControllerCreateContent(createContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerDeleteContent(contentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.contentControllerDeleteContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerGetContentById(contentId: string, options?: any): AxiosPromise<ContentEntity> {
            return localVarFp.contentControllerGetContentById(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerGetContentsByUnitId(unitId: string, options?: any): AxiosPromise<Array<ContentEntity>> {
            return localVarFp.contentControllerGetContentsByUnitId(unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerIsAccessibleToContent(contentId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.contentControllerIsAccessibleToContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerIsAccessibleToTest(unitId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.contentControllerIsAccessibleToTest(unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SortContentPageRequest} sortContentPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerSortContentPage(sortContentPageRequest: SortContentPageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.contentControllerSortContentPage(sortContentPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateTextContentRequest} updateTextContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerUpdateTextContent(updateTextContentRequest: UpdateTextContentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.contentControllerUpdateTextContent(updateTextContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateTitleRequest} updateTitleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentControllerUpdateTitle(updateTitleRequest: UpdateTitleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.contentControllerUpdateTitle(updateTitleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseRequest} createCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerCreateCourse(createCourseRequest: CreateCourseRequest, options?: any): AxiosPromise<string> {
            return localVarFp.courseControllerCreateCourse(createCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DeleteCourseRequest} deleteCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerDeleteCourse(deleteCourseRequest: DeleteCourseRequest, options?: any): AxiosPromise<string> {
            return localVarFp.courseControllerDeleteCourse(deleteCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetCourseById(courseId: string, options?: any): AxiosPromise<CourseEntity> {
            return localVarFp.courseControllerGetCourseById(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetCoursesByCompanyId(options?: any): AxiosPromise<Array<CourseEntity>> {
            return localVarFp.courseControllerGetCoursesByCompanyId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetCoursesByMyCompanyId(options?: any): AxiosPromise<Array<CourseEntity>> {
            return localVarFp.courseControllerGetCoursesByMyCompanyId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseRequest} updateCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerUpdateCourse(updateCourseRequest: UpdateCourseRequest, options?: any): AxiosPromise<void> {
            return localVarFp.courseControllerUpdateCourse(updateCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseMapRequest} createCourseMapRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerCreateCourseMap(createCourseMapRequest: CreateCourseMapRequest, options?: any): AxiosPromise<void> {
            return localVarFp.courseMapControllerCreateCourseMap(createCourseMapRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetCourseAssignRequest} getCourseAssignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseAssign(getCourseAssignRequest: GetCourseAssignRequest, options?: any): AxiosPromise<Array<CourseMapEntity>> {
            return localVarFp.courseMapControllerGetCourseAssign(getCourseAssignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMap(courseId: string, options?: any): AxiosPromise<GetCourseMapResponseDto> {
            return localVarFp.courseMapControllerGetCourseMap(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMapByCompanyId(options?: any): AxiosPromise<Array<CourseMapEntity>> {
            return localVarFp.courseMapControllerGetCourseMapByCompanyId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMapByCourseId(courseId: string, options?: any): AxiosPromise<Array<CourseMapEntity>> {
            return localVarFp.courseMapControllerGetCourseMapByCourseId(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerGetCourseMapByUserId(userId: string, options?: any): AxiosPromise<Array<GetCourseMapResponseDto>> {
            return localVarFp.courseMapControllerGetCourseMapByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseAssignedRequest} updateCourseAssignedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerUpsertCourseAssigned(updateCourseAssignedRequest: UpdateCourseAssignedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.courseMapControllerUpsertCourseAssigned(updateCourseAssignedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateCourseRequiredRequest} updateCourseRequiredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseMapControllerUpsertCourseRequired(updateCourseRequiredRequest: UpdateCourseRequiredRequest, options?: any): AxiosPromise<void> {
            return localVarFp.courseMapControllerUpsertCourseRequired(updateCourseRequiredRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateExamRequest} createExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerCreateEmptyExam(createExamRequest: CreateExamRequest, options?: any): AxiosPromise<string> {
            return localVarFp.examControllerCreateEmptyExam(createExamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {EditExamRequest} editExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerEditExamProblem(editExamRequest: EditExamRequest, options?: any): AxiosPromise<string> {
            return localVarFp.examControllerEditExamProblem(editExamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} examId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerGetExamAndProblems(examId: string, options?: any): AxiosPromise<GetExamProblemResponse> {
            return localVarFp.examControllerGetExamAndProblems(examId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerGetExamByUnitId(unitId: string, options?: any): AxiosPromise<Array<ExamEntity>> {
            return localVarFp.examControllerGetExamByUnitId(unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateExamRequest} updateExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerUpdateExam(updateExamRequest: UpdateExamRequest, options?: any): AxiosPromise<void> {
            return localVarFp.examControllerUpdateExam(updateExamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateExamOpeningRequest} updateExamOpeningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examControllerUpdateOpening(updateExamOpeningRequest: UpdateExamOpeningRequest, options?: any): AxiosPromise<void> {
            return localVarFp.examControllerUpdateOpening(updateExamOpeningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} examId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examProblemControllerGetExamProblems(examId: string, options?: any): AxiosPromise<Array<ExamProblemEntity>> {
            return localVarFp.examProblemControllerGetExamProblems(examId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateCommentRequest} createCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultCommentControllerCreateComment(createCommentRequest: CreateCommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.examResultCommentControllerCreateComment(createCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultCommentControllerGetComments(examResultId: string, options?: any): AxiosPromise<Array<ExamResultCommentEntity>> {
            return localVarFp.examResultCommentControllerGetComments(examResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateExamResultRequest} createExamResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerCreateExamResult(createExamResultRequest: CreateExamResultRequest, options?: any): AxiosPromise<void> {
            return localVarFp.examResultControllerCreateExamResult(createExamResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResults(examResultId: string, options?: any): AxiosPromise<GetExamResultsResponse> {
            return localVarFp.examResultControllerGetExamResults(examResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} learningUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResultsByUnitId(learningUnitId: string, options?: any): AxiosPromise<Array<ExamResultEntity>> {
            return localVarFp.examResultControllerGetExamResultsByUnitId(learningUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userExamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResultsByUserExamId(userExamId: string, options?: any): AxiosPromise<Array<ExamResultEntity>> {
            return localVarFp.examResultControllerGetExamResultsByUserExamId(userExamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetExamResultsByUserId(userId: string, options?: any): AxiosPromise<Array<ExamResultEntity>> {
            return localVarFp.examResultControllerGetExamResultsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerGetMyExamResults(options?: any): AxiosPromise<Array<ExamResultEntity>> {
            return localVarFp.examResultControllerGetMyExamResults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ScoreAndFeedbackRequest} scoreAndFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerUpdateExamResultScoreAndFeedback(scoreAndFeedbackRequest: ScoreAndFeedbackRequest, options?: any): AxiosPromise<void> {
            return localVarFp.examResultControllerUpdateExamResultScoreAndFeedback(scoreAndFeedbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {TotalScoreRequest} totalScoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examResultControllerUpdateExamResultTotalScoreAndFeedback(totalScoreRequest: TotalScoreRequest, options?: any): AxiosPromise<void> {
            return localVarFp.examResultControllerUpdateExamResultTotalScoreAndFeedback(totalScoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageControllerIssueImageDownloadURL(imageId: string, options?: any): AxiosPromise<string> {
            return localVarFp.imageControllerIssueImageDownloadURL(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateImageRequest} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageControllerIssueImageUploadURL(createImageRequest: CreateImageRequest, options?: any): AxiosPromise<CreateImageResponse> {
            return localVarFp.imageControllerIssueImageUploadURL(createImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateLearningUnitRequest} createLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerCreateLearningUnit(createLearningUnitRequest: CreateLearningUnitRequest, options?: any): AxiosPromise<string> {
            return localVarFp.learningUnitControllerCreateLearningUnit(createLearningUnitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DeleteLearningUnitRequest} deleteLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerDeleteLearningUnit(deleteLearningUnitRequest: DeleteLearningUnitRequest, options?: any): AxiosPromise<object> {
            return localVarFp.learningUnitControllerDeleteLearningUnit(deleteLearningUnitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerGetLearningUnitById(unitId: string, options?: any): AxiosPromise<LearningUnitEntity> {
            return localVarFp.learningUnitControllerGetLearningUnitById(unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerGetLearningUnitsByCourseId(courseId: string, options?: any): AxiosPromise<Array<LearningUnitEntity>> {
            return localVarFp.learningUnitControllerGetLearningUnitsByCourseId(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetLearningUnitByCourseIdsRequest} getLearningUnitByCourseIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerGetLearningUnitsByCourseIds(getLearningUnitByCourseIdsRequest: GetLearningUnitByCourseIdsRequest, options?: any): AxiosPromise<Array<LearningUnitEntity>> {
            return localVarFp.learningUnitControllerGetLearningUnitsByCourseIds(getLearningUnitByCourseIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SortLearningUnitRequest} sortLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerSortLearningUnit(sortLearningUnitRequest: SortLearningUnitRequest, options?: any): AxiosPromise<void> {
            return localVarFp.learningUnitControllerSortLearningUnit(sortLearningUnitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateLearningUnitRequest} updateLearningUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        learningUnitControllerUpdateLearningUnit(updateLearningUnitRequest: UpdateLearningUnitRequest, options?: any): AxiosPromise<void> {
            return localVarFp.learningUnitControllerUpdateLearningUnit(updateLearningUnitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetMessagesRequest} getMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerGetMessages(getMessagesRequest: GetMessagesRequest, options?: any): AxiosPromise<GetMessagesResponse> {
            return localVarFp.messageControllerGetMessages(getMessagesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerCheckMovieExistence(movieId: string, options?: any): AxiosPromise<string> {
            return localVarFp.movieControllerCheckMovieExistence(movieId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerDeleteMovie(movieId: string, options?: any): AxiosPromise<MovieEntity> {
            return localVarFp.movieControllerDeleteMovie(movieId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerGetMovieByContentId(contentId: string, options?: any): AxiosPromise<MovieEntity> {
            return localVarFp.movieControllerGetMovieByContentId(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} movieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerGetVTTFileURL(movieId: string, options?: any): AxiosPromise<string> {
            return localVarFp.movieControllerGetVTTFileURL(movieId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateMovieRequest} createMovieRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerIssueMovieUploadURL(createMovieRequest: CreateMovieRequest, options?: any): AxiosPromise<string> {
            return localVarFp.movieControllerIssueMovieUploadURL(createMovieRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateVTTRequest} updateVTTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movieControllerIssueVTTUploadURL(updateVTTRequest: UpdateVTTRequest, options?: any): AxiosPromise<string> {
            return localVarFp.movieControllerIssueVTTUploadURL(updateVTTRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moviePlayHistoryControllerCalcTotalWatchTime(options?: any): AxiosPromise<number> {
            return localVarFp.moviePlayHistoryControllerCalcTotalWatchTime(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moviePlayHistoryControllerGetWatchTimesForHalfYear(options?: any): AxiosPromise<GetWatchTimesForHalfYearResponse> {
            return localVarFp.moviePlayHistoryControllerGetWatchTimesForHalfYear(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SaveWatchTimeRequest} saveWatchTimeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moviePlayHistoryControllerSaveWatchTime(saveWatchTimeRequest: SaveWatchTimeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.moviePlayHistoryControllerSaveWatchTime(saveWatchTimeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateCourseProgressRequest} createCourseProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerCreateProgress(createCourseProgressRequest: CreateCourseProgressRequest, options?: any): AxiosPromise<void> {
            return localVarFp.progressControllerCreateProgress(createCourseProgressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerCourseByCompanyId(options?: any): AxiosPromise<Array<ProgressByCourseEntity>> {
            return localVarFp.progressControllerGetCompletedCountPerCourseByCompanyId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetCompletedCountByUserId} getCompletedCountByUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerCourseByUserId(getCompletedCountByUserId: GetCompletedCountByUserId, options?: any): AxiosPromise<Array<ProgressByCourseEntity>> {
            return localVarFp.progressControllerGetCompletedCountPerCourseByUserId(getCompletedCountByUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CountPerUnitByUnitIdsRequest} countPerUnitByUnitIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerUnitByUnitIds(countPerUnitByUnitIdsRequest: CountPerUnitByUnitIdsRequest, options?: any): AxiosPromise<Array<ProgressByUnitEntity>> {
            return localVarFp.progressControllerGetCompletedCountPerUnitByUnitIds(countPerUnitByUnitIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCountPerUnitByUserId(userId: string, options?: any): AxiosPromise<Array<ProgressByUnitEntity>> {
            return localVarFp.progressControllerGetCompletedCountPerUnitByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetCompletedCourseCount(options?: any): AxiosPromise<number> {
            return localVarFp.progressControllerGetCompletedCourseCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetCourseProgressRequest} getCourseProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetProgressByCourses(getCourseProgressRequest: GetCourseProgressRequest, options?: any): AxiosPromise<Array<GetCourseProgressResponse>> {
            return localVarFp.progressControllerGetProgressByCourses(getCourseProgressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetUnitProgressRequest} getUnitProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressControllerGetProgressByUnits(getUnitProgressRequest: GetUnitProgressRequest, options?: any): AxiosPromise<Array<GetUnitProgressResponse>> {
            return localVarFp.progressControllerGetProgressByUnits(getUnitProgressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} examResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentAnswerControllerGetStudentAnswersWithProblems(examResultId: string, options?: any): AxiosPromise<Array<StudentAnswerResponse>> {
            return localVarFp.studentAnswerControllerGetStudentAnswersWithProblems(examResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateTagIdRequestDto} createTagIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerCreateTag(createTagIdRequestDto: CreateTagIdRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.tagControllerCreateTag(createTagIdRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DeleteTagIdRequestDto} deleteTagIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerDeleteTag(deleteTagIdRequestDto: DeleteTagIdRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.tagControllerDeleteTag(deleteTagIdRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerGetAllTags(options?: any): AxiosPromise<Array<TagEntity>> {
            return localVarFp.tagControllerGetAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerGetTagItem(tagId: string, options?: any): AxiosPromise<TagEntity> {
            return localVarFp.tagControllerGetTagItem(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateTagRequestDto} updateTagRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagControllerUpdateTag(updateTagRequestDto: UpdateTagRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.tagControllerUpdateTag(updateTagRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate(userCreateRequest: UserCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerCreate(userCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteUsers(userId: string, options?: any): AxiosPromise<object> {
            return localVarFp.userControllerDeleteUsers(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserName(userId: string, options?: any): AxiosPromise<string> {
            return localVarFp.userControllerGetUserName(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUsers(options?: any): AxiosPromise<Array<UserEntityForResponse>> {
            return localVarFp.userControllerGetUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser(userUpdateRequest: UserUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUpdateUser(userUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTagControllerGetTagIDsByUserId(userId: string, options?: any): AxiosPromise<Array<UserTagEntity>> {
            return localVarFp.userTagControllerGetTagIDsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetUserTagsByUserIdsRequest} getUserTagsByUserIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTagControllerGetTagIDsByUserIds(getUserTagsByUserIdsRequest: GetUserTagsByUserIdsRequest, options?: any): AxiosPromise<Array<UserTagEntity>> {
            return localVarFp.userTagControllerGetTagIDsByUserIds(getUserTagsByUserIdsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {AccountPasswordResetRequest} accountPasswordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountControllerResetPassword(accountPasswordResetRequest: AccountPasswordResetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountControllerResetPassword(accountPasswordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {AccountPasswordResetInitiateRequest} accountPasswordResetInitiateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountControllerSendPasswordResetLink(accountPasswordResetInitiateRequest: AccountPasswordResetInitiateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountControllerSendPasswordResetLink(accountPasswordResetInitiateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {AuthAccessTokenRequest} authAccessTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerAccessToken(authAccessTokenRequest: AuthAccessTokenRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerAccessToken(authAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerMe(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * emailとpasswordでログイン。passwordは事前にハッシュをとったものを受け付けるが、これは不用意にログなどに生パスワードが出てしまうのを防ぐため。サーバー上で再度ハッシュをとっている。
     * @summary 
     * @param {AuthRefreshTokenRequest} authRefreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerRefreshToken(authRefreshTokenRequest: AuthRefreshTokenRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerRefreshToken(authRefreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateContentRequest} createContentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerCreateContent(createContentRequest: CreateContentRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerCreateContent(createContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerDeleteContent(contentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerDeleteContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerGetContentById(contentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerGetContentById(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerGetContentsByUnitId(unitId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerGetContentsByUnitId(unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerIsAccessibleToContent(contentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerIsAccessibleToContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerIsAccessibleToTest(unitId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerIsAccessibleToTest(unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SortContentPageRequest} sortContentPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerSortContentPage(sortContentPageRequest: SortContentPageRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerSortContentPage(sortContentPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateTextContentRequest} updateTextContentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerUpdateTextContent(updateTextContentRequest: UpdateTextContentRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerUpdateTextContent(updateTextContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateTitleRequest} updateTitleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contentControllerUpdateTitle(updateTitleRequest: UpdateTitleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contentControllerUpdateTitle(updateTitleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateCourseRequest} createCourseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseControllerCreateCourse(createCourseRequest: CreateCourseRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseControllerCreateCourse(createCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DeleteCourseRequest} deleteCourseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseControllerDeleteCourse(deleteCourseRequest: DeleteCourseRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseControllerDeleteCourse(deleteCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseControllerGetCourseById(courseId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseControllerGetCourseById(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseControllerGetCoursesByCompanyId(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseControllerGetCoursesByCompanyId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseControllerGetCoursesByMyCompanyId(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseControllerGetCoursesByMyCompanyId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateCourseRequest} updateCourseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseControllerUpdateCourse(updateCourseRequest: UpdateCourseRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseControllerUpdateCourse(updateCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateCourseMapRequest} createCourseMapRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerCreateCourseMap(createCourseMapRequest: CreateCourseMapRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerCreateCourseMap(createCourseMapRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetCourseAssignRequest} getCourseAssignRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerGetCourseAssign(getCourseAssignRequest: GetCourseAssignRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerGetCourseAssign(getCourseAssignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerGetCourseMap(courseId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerGetCourseMap(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerGetCourseMapByCompanyId(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerGetCourseMapByCompanyId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerGetCourseMapByCourseId(courseId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerGetCourseMapByCourseId(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerGetCourseMapByUserId(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerGetCourseMapByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateCourseAssignedRequest} updateCourseAssignedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerUpsertCourseAssigned(updateCourseAssignedRequest: UpdateCourseAssignedRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerUpsertCourseAssigned(updateCourseAssignedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateCourseRequiredRequest} updateCourseRequiredRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public courseMapControllerUpsertCourseRequired(updateCourseRequiredRequest: UpdateCourseRequiredRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).courseMapControllerUpsertCourseRequired(updateCourseRequiredRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateExamRequest} createExamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examControllerCreateEmptyExam(createExamRequest: CreateExamRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examControllerCreateEmptyExam(createExamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {EditExamRequest} editExamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examControllerEditExamProblem(editExamRequest: EditExamRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examControllerEditExamProblem(editExamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} examId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examControllerGetExamAndProblems(examId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examControllerGetExamAndProblems(examId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examControllerGetExamByUnitId(unitId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examControllerGetExamByUnitId(unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateExamRequest} updateExamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examControllerUpdateExam(updateExamRequest: UpdateExamRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examControllerUpdateExam(updateExamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateExamOpeningRequest} updateExamOpeningRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examControllerUpdateOpening(updateExamOpeningRequest: UpdateExamOpeningRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examControllerUpdateOpening(updateExamOpeningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} examId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examProblemControllerGetExamProblems(examId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examProblemControllerGetExamProblems(examId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateCommentRequest} createCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultCommentControllerCreateComment(createCommentRequest: CreateCommentRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultCommentControllerCreateComment(createCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} examResultId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultCommentControllerGetComments(examResultId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultCommentControllerGetComments(examResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateExamResultRequest} createExamResultRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerCreateExamResult(createExamResultRequest: CreateExamResultRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerCreateExamResult(createExamResultRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} examResultId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerGetExamResults(examResultId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerGetExamResults(examResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} learningUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerGetExamResultsByUnitId(learningUnitId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerGetExamResultsByUnitId(learningUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userExamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerGetExamResultsByUserExamId(userExamId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerGetExamResultsByUserExamId(userExamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerGetExamResultsByUserId(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerGetExamResultsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerGetMyExamResults(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerGetMyExamResults(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ScoreAndFeedbackRequest} scoreAndFeedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerUpdateExamResultScoreAndFeedback(scoreAndFeedbackRequest: ScoreAndFeedbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerUpdateExamResultScoreAndFeedback(scoreAndFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {TotalScoreRequest} totalScoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public examResultControllerUpdateExamResultTotalScoreAndFeedback(totalScoreRequest: TotalScoreRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).examResultControllerUpdateExamResultTotalScoreAndFeedback(totalScoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public imageControllerIssueImageDownloadURL(imageId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).imageControllerIssueImageDownloadURL(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateImageRequest} createImageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public imageControllerIssueImageUploadURL(createImageRequest: CreateImageRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).imageControllerIssueImageUploadURL(createImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateLearningUnitRequest} createLearningUnitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerCreateLearningUnit(createLearningUnitRequest: CreateLearningUnitRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerCreateLearningUnit(createLearningUnitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DeleteLearningUnitRequest} deleteLearningUnitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerDeleteLearningUnit(deleteLearningUnitRequest: DeleteLearningUnitRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerDeleteLearningUnit(deleteLearningUnitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerGetLearningUnitById(unitId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerGetLearningUnitById(unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerGetLearningUnitsByCourseId(courseId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerGetLearningUnitsByCourseId(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetLearningUnitByCourseIdsRequest} getLearningUnitByCourseIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerGetLearningUnitsByCourseIds(getLearningUnitByCourseIdsRequest: GetLearningUnitByCourseIdsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerGetLearningUnitsByCourseIds(getLearningUnitByCourseIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SortLearningUnitRequest} sortLearningUnitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerSortLearningUnit(sortLearningUnitRequest: SortLearningUnitRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerSortLearningUnit(sortLearningUnitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateLearningUnitRequest} updateLearningUnitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public learningUnitControllerUpdateLearningUnit(updateLearningUnitRequest: UpdateLearningUnitRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).learningUnitControllerUpdateLearningUnit(updateLearningUnitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetMessagesRequest} getMessagesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public messageControllerGetMessages(getMessagesRequest: GetMessagesRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).messageControllerGetMessages(getMessagesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} movieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public movieControllerCheckMovieExistence(movieId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).movieControllerCheckMovieExistence(movieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} movieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public movieControllerDeleteMovie(movieId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).movieControllerDeleteMovie(movieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public movieControllerGetMovieByContentId(contentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).movieControllerGetMovieByContentId(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} movieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public movieControllerGetVTTFileURL(movieId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).movieControllerGetVTTFileURL(movieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateMovieRequest} createMovieRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public movieControllerIssueMovieUploadURL(createMovieRequest: CreateMovieRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).movieControllerIssueMovieUploadURL(createMovieRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateVTTRequest} updateVTTRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public movieControllerIssueVTTUploadURL(updateVTTRequest: UpdateVTTRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).movieControllerIssueVTTUploadURL(updateVTTRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moviePlayHistoryControllerCalcTotalWatchTime(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).moviePlayHistoryControllerCalcTotalWatchTime(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moviePlayHistoryControllerGetWatchTimesForHalfYear(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).moviePlayHistoryControllerGetWatchTimesForHalfYear(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SaveWatchTimeRequest} saveWatchTimeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moviePlayHistoryControllerSaveWatchTime(saveWatchTimeRequest: SaveWatchTimeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).moviePlayHistoryControllerSaveWatchTime(saveWatchTimeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateCourseProgressRequest} createCourseProgressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerCreateProgress(createCourseProgressRequest: CreateCourseProgressRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerCreateProgress(createCourseProgressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetCompletedCountPerCourseByCompanyId(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetCompletedCountPerCourseByCompanyId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetCompletedCountByUserId} getCompletedCountByUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetCompletedCountPerCourseByUserId(getCompletedCountByUserId: GetCompletedCountByUserId, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetCompletedCountPerCourseByUserId(getCompletedCountByUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CountPerUnitByUnitIdsRequest} countPerUnitByUnitIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetCompletedCountPerUnitByUnitIds(countPerUnitByUnitIdsRequest: CountPerUnitByUnitIdsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetCompletedCountPerUnitByUnitIds(countPerUnitByUnitIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetCompletedCountPerUnitByUserId(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetCompletedCountPerUnitByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetCompletedCourseCount(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetCompletedCourseCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetCourseProgressRequest} getCourseProgressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetProgressByCourses(getCourseProgressRequest: GetCourseProgressRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetProgressByCourses(getCourseProgressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetUnitProgressRequest} getUnitProgressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressControllerGetProgressByUnits(getUnitProgressRequest: GetUnitProgressRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressControllerGetProgressByUnits(getUnitProgressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} examResultId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studentAnswerControllerGetStudentAnswersWithProblems(examResultId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studentAnswerControllerGetStudentAnswersWithProblems(examResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateTagIdRequestDto} createTagIdRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tagControllerCreateTag(createTagIdRequestDto: CreateTagIdRequestDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tagControllerCreateTag(createTagIdRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DeleteTagIdRequestDto} deleteTagIdRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tagControllerDeleteTag(deleteTagIdRequestDto: DeleteTagIdRequestDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tagControllerDeleteTag(deleteTagIdRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tagControllerGetAllTags(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tagControllerGetAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tagControllerGetTagItem(tagId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tagControllerGetTagItem(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateTagRequestDto} updateTagRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tagControllerUpdateTag(updateTagRequestDto: UpdateTagRequestDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tagControllerUpdateTag(updateTagRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserCreateRequest} userCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerCreate(userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerCreate(userCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerDeleteUsers(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerDeleteUsers(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetUserName(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetUserName(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetUsers(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserUpdateRequest} userUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerUpdateUser(userUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerUpdateUser(userUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userTagControllerGetTagIDsByUserId(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userTagControllerGetTagIDsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetUserTagsByUserIdsRequest} getUserTagsByUserIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userTagControllerGetTagIDsByUserIds(getUserTagsByUserIdsRequest: GetUserTagsByUserIdsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userTagControllerGetTagIDsByUserIds(getUserTagsByUserIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


