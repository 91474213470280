/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import videojs from 'video.js';
import { MovieEntity } from "../../../generated-api";
import { apiClientSelector } from "../../../selectors/api";

const videoNodeId = 'videojs-player';

const watchTime = {
  startTime: 0,
  stopTime: 0,
  totalTime: 0,
}

type Props = {
  movie: MovieEntity;
}

const MovieContent = ({ movie }: Props) => {
  const api = useRecoilValue(apiClientSelector);
  const [player, setPlayer] = useState<videojs.Player>();

  useEffect(() => {
    if (!player || !api) {
      return;
    }
    // You can handle player events here, for example:
    player?.on('play', () => {
      watchTime.startTime = Math.round(player.currentTime());
    });

    player?.on('pause', async () => {
      watchTime.stopTime = Math.round(player.currentTime());
      if (watchTime.stopTime - watchTime.startTime > 0) {
        watchTime.totalTime = watchTime.stopTime - watchTime.startTime;
        watchTime.startTime = watchTime.stopTime; // 必須ではない
        await api.moviePlayHistoryControllerSaveWatchTime({ watchSeconds: watchTime.totalTime });
      }
    });

  }, [player, api]);

  useEffect(() => {
    (async () => {
      const movieURL = (await api.movieControllerCheckMovieExistence(movie.movie_id)).data;
      // 字幕は無くても読み込む
      const vttFileURL = (await api.movieControllerGetVTTFileURL(movie.movie_id)).data;

      setPlayer(videojs(videoNodeId, {
        controls: true,
        muted: false,
        sources: [{
          src: movieURL,
          type: 'application/x-mpegURL'
        }],
        width: 750,
        height: 600,
        fluid: true,
        tracks: [{
          kind: 'captions',
          default: true,
          src: vttFileURL ?? '',
          label: 'current',
        }],
      }));

    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (player) {
        player.dispose();
      }
    }
  });

  return (
    <div className="c-player">
      <div className="c-player__screen" data-vjs-player="true">
        <video
          id={videoNodeId}
          className="video-js"
          crossOrigin="anonymous"
        />
      </div>
    </div>
  );
}


export default MovieContent;