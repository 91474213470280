import { getUnixTime } from 'date-fns';
import { atom, AtomEffect } from 'recoil';

// 参考: https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
export const localStorageEffect: (key: string) => AtomEffect<string | undefined> =
  (key) =>
    ({ setSelf, onSet }) => {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(savedValue);
      }

      onSet((newValue, _, isReset) => {
        if (isReset || !newValue) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, newValue);
        }
      });
    };

export const timeAtom = atom<number>({
  key: 'seconds',
  default: getUnixTime(new Date()),
  effects: [
    ({ setSelf }) => {
      const interval = setInterval(() => {
        setSelf(getUnixTime(new Date()));
      }, 1000);
      return () => {
        console.log('stop timer');
        clearInterval(interval);
      };
    },
  ],
});

export function spreadYyyyMmPeriod(start_yyyymm: string, end_yyyymm: string): string[] {
  const start_yyyy = parseInt(start_yyyymm.slice(0, 4), 10);
  const start_mm = parseInt(start_yyyymm.slice(4), 10);
  const end_yyyy = parseInt(end_yyyymm.slice(0, 4), 10);
  const end_mm = parseInt(end_yyyymm.slice(4), 10);
  const period_as_month = end_mm - start_mm + 12 * (end_yyyy - start_yyyy);

  const yyyymms: string[] = [];
  let yyyy = start_yyyy;
  let mm = start_mm;
  for (let m_count = 0; m_count <= period_as_month; m_count += 1) {
    yyyymms.push(yyyy.toString() + mm.toString().padStart(2, '0'));
    if (mm === 12) {
      yyyy += 1;
      mm = 1;
    } else {
      mm += 1;
    }
  }
  return yyyymms;
}

export const byteLength = (s: string) => {
  return encodeURI(s).replace(/%../g, '*').length;
};
