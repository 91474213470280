import { selector } from "recoil";
import { Configuration, DefaultApiFactory } from "../generated-api";
import { accessTokenResolver } from "./auth";

export type ApiClientType = ReturnType<typeof DefaultApiFactory>;

export const apiClientSelector = selector<ApiClientType>({
  key: "apiClientSelector",
  get: ({ get }) => {
    const resolver = get(accessTokenResolver);
    return DefaultApiFactory(
      new Configuration({
        accessToken: () => {
          return resolver() || "";
        },
        baseOptions: {
          withCredentials: true
        }
      }),
      window.location.host.indexOf("localhost") === -1 ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:5000",
    );
  },
});