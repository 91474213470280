import Delimiter from '@editorjs/delimiter';
import EditorJS, { OutputData } from "@editorjs/editorjs";
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { ContentEntity } from "../../../generated-api";
import { apiClientSelector } from "../../../selectors/api";
import { ContentEditor } from '../../contentsManagement/content-components/TextContentEdit';

const EDITTOR_HOLDER_ID = 'editor-js'
const LIMIT_CONTENT_SIZE = 380000;

const editorJsTools = {
  header: Header,
  list: List,
  image: {
    class: ImageTool,
    config: {
      uploader: {},
    },
  },
  delimiter: Delimiter,
}

type Props = {
  targetContent: ContentEntity;
}

const TextContentEdit = (props: Props) => {
  const { targetContent } = props;

  const api = useRecoilValue(apiClientSelector);

  const editorJsRef = useRef<EditorJS>();
  const editorDOMRef = useRef<HTMLDivElement>(null); // 開発時のみ（useEffectが２回走るため）

  const initEditor = async (initData: OutputData) => {

    const blocksWithImage = await Promise.all(initData.blocks.map(async (item) => {
      if (item.type !== 'image') {
        return item;
      }

      const value = await api.imageControllerIssueImageDownloadURL(item.data.file.url);

      return {
        ...item,
        data: {
          ...item.data,
          file: {
            url: `${value.data}#imageId=${item.data.file.url}`,
          },
        }
      }
    }));

    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      // logLevel: 'Error' as LogLevels,
      readOnly: true,
      data: { ...initData, blocks: blocksWithImage },
      onReady: () => {
        editorJsRef.current = editor;

        // 開発時のみ（useEffectが２回走るため）
        const selector = editorDOMRef.current?.getElementsByClassName('codex-editor');
        if (selector && selector.length > 1) {
          for (let i = 0; selector.length - 1; i += 1) {
            selector[i].remove();
          }
        }

      },
      // autofocus: true,
      tools: editorJsTools,
    });
  };


  useEffect(() => {

    (async () => {
      if (!editorJsRef.current && targetContent.content_json) {
        initEditor(JSON.parse(targetContent.content_json) as OutputData);
      }
    })();

    return () => {
      if (editorJsRef) {
        editorJsRef.current?.destroy();
        editorJsRef.current = undefined;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentEditor id={EDITTOR_HOLDER_ID} ref={editorDOMRef} />
  );
};

export default TextContentEdit;