import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { DefaultLayout } from './layouts/DefaultLayout';
import ContentEdit from './pages/contentsManagement/ContentEdit';
import ScoreExamResult from './pages/contentsManagement/ScoreExamResult';
import Content from './pages/course/Content';
import PrepareTest from './pages/course/PrepareTest';
import { RequireAuth } from './selectors/auth';

const CourseMap = React.lazy(() => import('./pages/course/CourseMap'));
const CourseDetail = React.lazy(() => import('./pages/course/CourseDetail'));
const TestForStudent = React.lazy(() => import('./pages/course/TestForStudent'));
const MyPage = React.lazy(() => import('./pages/myPage/MyPage'));
const Member = React.lazy(() => import('./pages/member/Member'));
const MemberDetail = React.lazy(() => import('./pages/member/MemberDetail'));
const Management = React.lazy(() => import('./pages/contentsManagement/ManagementTop'));
const CourseEdit = React.lazy(() => import('./pages/contentsManagement/CourseEdit'));
const UnitEdit = React.lazy(() => import('./pages/contentsManagement/UnitEdit'));
const Login = React.lazy(() => import('./pages/login/Login'));
const PasswordResetPage = React.lazy(() => import('./pages/login/PasswordReset'));
const PasswordResetChangePasswordPage = React.lazy(() => import('./pages/login/PasswordResetChangePasswordPage'));
const PasswordResetCompletedPage = React.lazy(() => import('./pages/login/PasswordResetCompletedPage'));
const LearningProgress = React.lazy(() => import('./pages/learningProgress/LearningProgress'));
const ExamEdit = React.lazy(() => import('./pages/contentsManagement/ExamEdit'));
const ExamResult = React.lazy(() => import('./pages/exam/ExamResult'));

const App = () => (
  <Suspense fallback="loading...">
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/login" element={<Login />} />
          <Route path="/password_reset" element={<PasswordResetPage />} />
          <Route
            path="/password_reset_from_email"
            element={<PasswordResetChangePasswordPage />}
          />
          <Route
            path="/password_reset_completed"
            element={<PasswordResetCompletedPage />}
          />
        </Route>
        <Route element={<RequireAuth />}>
          <Route element={<DefaultLayout />}>
            <Route index element={<MyPage />} />
            <Route path="/course" element={<CourseMap />} />
            <Route path="/course/:courseId" element={<CourseDetail />} />
            <Route path="/course/:courseId/unit/:unitId/content/:contentId" element={<Content />} />
            <Route path="/course/:courseId/unit/:unitId/prepare-test" element={<PrepareTest />} />
            <Route path="/course/:courseId/unit/:unitId/test/:testId" element={<TestForStudent />} />
            <Route path="/learning_progress" element={<LearningProgress />} />
            <Route path="/member" element={<Member />} />
            <Route path="/member/:memberId" element={<MemberDetail />} />
            <Route path="/contents_management" element={<Management />} />
            <Route path="/contents_management/course/:courseId" element={<CourseEdit />} />
            <Route path="/contents_management/course/:courseId/unit/:unitId" element={<UnitEdit />} />
            <Route path="/contents_management/course/:courseId/unit/:unitId/content/:contentId" element={<ContentEdit />} />
            <Route path="/contents_management/course/:courseId/unit/:unitId/test/:examId" element={<ExamEdit />} />
            <Route path="/contents_management/test_result/:examId/:examResultId" element={<ScoreExamResult />} />
            <Route path="/test_result/:examResultId" element={<ExamResult />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </Suspense>
);

export default App;
