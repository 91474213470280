import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { apiClientSelector } from "../../../selectors/api";

const Uploader = styled(Upload)`
  .ant-upload-list {
    display: none;
  }
`

type Props = {
  movieId: string;
}

const VTTUplaoder = (props: Props) => {
  const { movieId } = props;
  const api = useRecoilValue(apiClientSelector);

  return (
    <Uploader
      name='file'
      customRequest={async (options: any) => {

        const url = (await api.movieControllerIssueVTTUploadURL(
          {
            movieId,
          },
        )).data;

        await fetch(url, {
          method: 'PUT',
          body: options.file,
        });

        alert(`VTTファイルを更新しました。\n動画を再読み込みし、動画右下のCCより、新しい字幕を選らんでください。`);
      }}
    >
      <Button icon={<UploadOutlined />}>字幕ファイル(VTT)をアップロードする</Button>
    </Uploader >
  );
};

export default VTTUplaoder;