import { Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

type Props = {
  title: string;
  mb?: string
  color?: string
};

export const PageTitle: React.FC<Props> = (props: Props) => {
  const { title, mb, color } = props;
  return <Title level={3} style={{ marginBottom: mb ? mb.concat("px") : "0px", color: color || "black" }}>{title}</Title>;
};
